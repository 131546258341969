import { Button as AntButton, ButtonProps } from 'antd';
import { ButtonType } from 'antd/lib/button';

import cn from 'classnames';

import s from './s.module.scss';

export type ExtendButtonType = ButtonType | 'secondary';

type Props = Omit<ButtonProps, 'type'> & { type: ExtendButtonType };

const Button = ({ children, className, type, ...props }: Props) => {
  return (
    <AntButton
      {...props}
      type={type as ButtonType}
      className={cn(
        s.button,
        className,
        type === 'primary' && s.primary,
        type === 'secondary' && s.secondary,
        type === 'ghost' && s.ghost,
        type === 'link' && s.link
      )}
    >
      {children}
    </AntButton>
  );
};

export default Button;
