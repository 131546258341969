import React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';

export const LOCALSTORAGE_LANGUAGE_KEY = 'language';

const SupportedLanguages = [
  {
    key: 'en',
    name_key: 'English',
  },
  {
    key: 'zh-CN',
    name_key: '中文（简体）',
  },
  {
    key: 'zh-TW',
    name_key: '中文（繁體）',
  },
  {
    key: 'ja',
    name_key: '日本語',
  },
  {
    key: 'ko',
    name_key: '한국어',
  },
];

const SelectLanguage = () => {
  const { t, i18n } = useTranslation();

  const defaultValue = localStorage.getItem(LOCALSTORAGE_LANGUAGE_KEY);

  const onSelectLanguage = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem(LOCALSTORAGE_LANGUAGE_KEY, value);
  };

  return (
    <Select defaultValue={defaultValue ?? 'en'} style={{ width: 170 }} onChange={onSelectLanguage}>
      {SupportedLanguages.map((x) => (
        <Select.Option key={x.key} value={x.key}>
          {x.name_key}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectLanguage;
