import React, { lazy, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from '../../auth/protected-route';
import ProfileContainer from '../../components/profile-container';
import { UserProfileNavigations, getUserProfileNavigation } from 'layout/navigations';
import ProfileProvider from 'providers/profile-provider';

const VerificationView = lazy(() => import('../../modules/verification'));
const AccountView = lazy(() => import('../../modules/account'));

type Params = {
  module: string;
};

const UserProfile: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { module } = match.params as Params;
  useEffect(() => {
    if (!['verification', 'account'].includes(module)) {
      history.push('/user/verification/overview');
    }
  }, []);

  return (
    <ProfileProvider>
      <ProfileContainer
        navigationItems={UserProfileNavigations}
        title={getUserProfileNavigation(module)?.title_key || ''}
        hideSideNavigation
      >
        <ProtectedRoute path="/user/verification" component={VerificationView} />
        <ProtectedRoute path="/user/account" component={AccountView} />
      </ProfileContainer>
    </ProfileProvider>
  );
};

export default UserProfile;
