import Layout from 'antd/lib/layout';

import ApiProvider from 'chain-api/api-provider';
import DashboardFooter from 'layout/dashboard-footer';
import DashboardHeader from 'layout/dashboard-header';
import AssetsProvider from 'providers/assets-provider';
import ProfileProvider from 'providers/profile-provider';
import WalletProvider from 'wallet/wallet-provider';

import './s.module.scss';

interface Props {
  children: React.ReactNode;
}

const DashboardPage: React.FC<Props> = ({ children }) => {
  return (
    <ProfileProvider>
      <Layout>
        <ApiProvider>
          <AssetsProvider>
            <WalletProvider>
              <DashboardHeader />
              <Layout>{children}</Layout>
              <DashboardFooter />
            </WalletProvider>
          </AssetsProvider>
        </ApiProvider>
      </Layout>
    </ProfileProvider>
  );
};

export default DashboardPage;
