import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'antd/lib/button';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Identicon } from '@polkadot/react-identicon';

import cn from 'classnames';

import Divider from 'components/divider';
import Grid from 'components/grid';
import Popover from 'components/popover';
import { Text } from 'components/typography';
import ConnectWalletButton from 'modules/dashboard/components/connect-wallet-button';
import { shortenAddr, shortenName } from 'wallet/utils';
import { useWallet } from 'wallet/wallet-provider';

import s from './s.module.scss';

const ConnectedWallet: React.FC = () => {
  const { t } = useTranslation();
  const { selectedAccount, changeAccount: changeWallet, disconnect } = useWallet();

  const AccountSection = () => (
    <Popover
      placement="bottomRight"
      trigger="click"
      noPadding
      className={s.popover}
      content={
        <div className="card">
          <Grid className="card-header" flow="col" gap={16} align="center" justify="start">
            <Identicon value={selectedAccount?.address} size={40} theme="substrate" />
            <Text type="p2" color="primary">
              {shortenAddr(selectedAccount?.address)}
            </Text>
          </Grid>
          <Grid flow="row" gap={32} padding={[32, 24]}>
            <Grid flow="col" gap={16} colsTemplate="24px 1fr auto" justify="start">
              <Text type="p2" color="secondary">
                {t('wallet_connect_dropdown_name')}
              </Text>
              <Text type="p2" color="primary" className={s.value}>
                {selectedAccount?.name}
              </Text>
            </Grid>
            <Grid flow="col" gap={16} colsTemplate="24px 1fr auto">
              <Text type="p2" color="secondary">
                {t('wallet_connect_dropdown_status')}
              </Text>
              <Text type="lb2" className={cn(s.statusTag, s.value)}>
                {t('wallet_connect_dropdown_status_connected')}
              </Text>
            </Grid>
          </Grid>
          <>
            <Divider />
            <Grid flow="col" gap={16} padding={24}>
              <Button
                type="ghost"
                onClick={() => changeWallet()}
                className={cn(s.button, s.ghostButton)}
              >
                {t('wallet_connect_dropdown_change_btn')}
              </Button>
              <Button
                type="primary"
                disabled={!selectedAccount}
                onClick={disconnect}
                className={s.button}
              >
                {t('wallet_connect_dropdown_disconnect_btn')}
              </Button>
            </Grid>
          </>
        </div>
      }
    >
      <Button type="link" className={s.accountLink}>
        <Grid flow="col" align="center">
          <Identicon
            value={selectedAccount?.address}
            size={26}
            theme="substrate"
            className="mr-8"
          />
          <Text type="p1" className={cn(s.walletAddress, 'mr-8')}>
            {shortenName(selectedAccount?.name)}
          </Text>
          <FontAwesomeIcon icon={faChevronDown} className={s.dropdownArrow} />
        </Grid>
      </Button>
    </Popover>
  );

  return (
    <>
      {/* {selectedBalance && (
        <div>
          <Balances value={selectedBalance} balances={balances} onChange={setSelectedBalance} />
        </div>
      )} */}

      {selectedAccount ? <AccountSection /> : <ConnectWalletButton />}
    </>
  );
};

export default memo(ConnectedWallet);
