import React from 'react';

import cn from 'classnames';

import Logo from '../logo';
import SelectLanguage from '../select-language';
import TopNav from '../top-nav';
import Heading from './heading';
import styles from './s.module.scss';

interface Props {
  headerWide?: boolean | false;
  displayHeading?: boolean;
  onBack?: () => void;
  heading?: string;
}

const Header: React.FC<Props> = ({ headerWide, displayHeading = false, onBack, heading }) => {
  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn(styles.container)}>
        <a className={styles.logo} href={'https://www.ottoblockchain.com/'}>
          <Logo />
        </a>
        <div className={styles.wrapper}>
          <SelectLanguage />
          <div className={styles.control}>
            <TopNav />
          </div>
        </div>
      </div>
      {displayHeading && <Heading onBack={onBack} heading={heading} />}
    </header>
  );
};

export default Header;
