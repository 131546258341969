import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { verfiyEmail } from 'utils/fetchRequest';

import AuthCard from './AuthCard';
import styles from './s.module.scss';

interface Props {
  email: string;
  onContinue?: () => void;
}

const AuthVerifyEmail: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();
  const [clickableAt, setClickableAt] = useState<number>(0);
  const [secLeft, setSecLeft] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (clickableAt - new Date().getTime()) {
        setSecLeft(Math.floor((clickableAt - new Date().getTime()) / 1000));
      } else if (secLeft > 0) {
        setSecLeft(0);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [clickableAt]);

  const onResendEmail = () => {
    verfiyEmail(email).then(() => {
      setClickableAt(new Date().getTime() + 15 * 1000);
    });
  };
  return (
    <AuthCard wide title={t('auth_verify_email')} desc={t('auth_verify_email_content', { email })}>
      <Button
        size="large"
        type="primary"
        className={styles.button}
        onClick={onResendEmail}
        disabled={secLeft > 0}
      >
        {t('auth_verify_email_resend')} {secLeft > 0 ? `(${secLeft})` : ''}
      </Button>
    </AuthCard>
  );
};

export default AuthVerifyEmail;
