import { useTranslation } from 'react-i18next';

import { Typography } from 'antd';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import clx from 'classnames';

import s from './s.module.scss';

interface IProps {
  onBack?: () => void;
  heading?: string;
}

const Heading = ({ onBack, heading }: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={clx('container', s.heading_container)}>
      <div className={s.heading_btn}>
        {onBack && (
          <div className={s.heading_back} onClick={onBack}>
            <FontAwesomeIcon className={s.heading_back_icon} icon={faChevronLeft} />
            <Typography.Title className={s.heading_back_label} level={3}>
              {t('nav_heading_back')}
            </Typography.Title>
          </div>
        )}
      </div>
      <Typography.Title className={s.heading_title} level={1}>
        {heading}
      </Typography.Title>
      <div className={s.heading_btn} />
    </div>
  );
};

export default Heading;
