import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import ErrorBoundary from './components/error-boundary';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { LOCALSTORAGE_LANGUAGE_KEY } from 'layout/select-language';

import './i18n';
import LayoutView from './layout';

const env = config.env;
if (env !== 'local') {
  Sentry.init({
    dsn: config.sentry.dsn,
    debug: true,
    environment: config.env,
  });
}

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem(LOCALSTORAGE_LANGUAGE_KEY);
    i18n.changeLanguage(language ?? 'en');
  }, [i18n]);

  const client = new ApolloClient({
    uri: config.api.baseUrl,
    cache: new InMemoryCache(),
  });

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <LayoutView />
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
