import { ReactNode } from 'react';

import AntdModal, { ModalProps } from 'antd/lib/modal';

import Button, { ExtendButtonType } from 'components/button';
import { Text } from 'components/typography';
import FailureIcon from 'resources/svg/failure.svg';
import PendingIcon from 'resources/svg/modal-pending.svg';
import SuccessIcon from 'resources/svg/success.svg';

import s from './s.module.scss';

export interface IGeneralModal extends ModalProps {
  type: 'success' | 'failure' | 'pending';
  title?: string;
  description?: string;
  comment?: string;
  note?: string | ReactNode;
  buttonLabel?: string;
  buttonType?: ExtendButtonType;
  onButtonClick?: () => void;
  secondButtonLabel?: string;
  secondButtonType?: ExtendButtonType;
  onSecondButtonClick?: () => void;
  thirdButtonLabel?: string;
  thirdButtonType?: ExtendButtonType;
  onThirdButtonClick?: () => void;
  onCancel?: (e?: React.MouseEvent<HTMLElement>) => void;
}

const ICONS = {
  success: SuccessIcon,
  failure: FailureIcon,
  pending: PendingIcon,
};

const GeneralModal = ({
  type,
  title,
  description,
  comment,
  note,
  buttonLabel,
  buttonType = 'secondary',
  onButtonClick,
  secondButtonLabel,
  secondButtonType,
  onSecondButtonClick,
  thirdButtonLabel,
  thirdButtonType,
  onThirdButtonClick,
  onCancel,
  ...rest
}: IGeneralModal) => {
  return (
    <AntdModal
      zIndex={1000}
      open
      centered
      closable={!!onCancel}
      footer={null}
      className={s.newModal}
      onCancel={onCancel}
      {...rest}
    >
      <Text type="p1" className={s.title}>
        {title}
      </Text>

      {description && (
        <Text type="p1" className={s.description}>
          {description}
        </Text>
      )}

      {comment && (
        <Text type="p1" className={s.comment}>
          {comment}
        </Text>
      )}

      {ICONS[type] && <img src={ICONS[type]} alt="icon" className="mt-32" />}

      {typeof note === 'string' ? (
        <Text type="p1" className={s.note}>
          {note}
        </Text>
      ) : (
        note && note
      )}

      {buttonLabel && (
        <Button type={buttonType} className={s.button} size="large" onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      )}

      {secondButtonLabel && secondButtonType && (
        <Button
          type={secondButtonType}
          className={s.button}
          size="large"
          onClick={onSecondButtonClick}
        >
          {secondButtonLabel}
        </Button>
      )}
      {thirdButtonLabel && thirdButtonType && (
        <Button
          type={thirdButtonType}
          className={s.button}
          size="large"
          onClick={onThirdButtonClick}
        >
          {thirdButtonLabel}
        </Button>
      )}
    </AntdModal>
  );
};

export default GeneralModal;
