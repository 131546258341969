// navigation configurations for feature use.
import { faCheckCircle, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';

import { Navigation } from 'types/navigation';

export const getUserProfileNavigation = (key: string): Navigation | undefined =>
  UserProfileNavigations.find((x) => x.key === key);

export const UserProfileNavigations: Navigation[] = [
  {
    key: 'profile',
    title_key: 'nav_profile',
    icon: faUser,
    url: '/user/account',
    separator: false,
  },
  {
    key: 'verification',
    title_key: 'nav_verification',
    icon: faCheckCircle,
    url: '/user/verification/overview',
    separator: false,
  },
  {
    key: 'wallet',
    title_key: 'nav_wallet',
    icon: faWallet,
    url: '/user/wallet',
    separator: false,
  },
];

export const SiteNavigation = [
  {
    title: 'Exchange',
    url: '/exchange',
  },
  {
    title: 'Buy Crypto',
    dropdown: [
      {
        title: 'Credit card',
        icon: 'user',
        url: '/buy-crypto',
      },
      {
        title: 'Bank deposit',
        icon: 'image',
        url: '/deposit-fiat',
      },
    ],
  },
  {
    title: 'Market',
    url: '/market',
  },
  {
    title: 'Discover',
    url: '/learn-crypto',
  },
];
