import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import config from 'config';
import Web3 from 'web3';

import { Web3ReactProvider } from '@web3-react/core';
import Maintenance from 'modules/maintenance';
import 'styles/antd/index.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';

function getLibrary(provider) {
  return new Web3(provider);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  config.isMaintenanceMode ? (
    <Maintenance />
  ) : (
    <Router>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </Router>
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
