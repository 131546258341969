import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import { useWallet } from 'wallet/wallet-provider';

import s from './s.module.scss';

const ConnectWalletButton = () => {
  const { connect } = useWallet();
  const { t } = useTranslation();

  return (
    <Button type="secondary" onClick={connect} className={s.button}>
      {t('btn_connect')}
    </Button>
  );
};

export default ConnectWalletButton;
