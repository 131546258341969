import React, { ReactNode } from 'react';

import { Tooltip } from 'antd';

import cn from 'classnames';
import { TFunctionResult } from 'i18next';

import InfoLightSvg from 'resources/svg/info-light.svg';
import InfoSvg from 'resources/svg/info.svg';

import s from './s.module.scss';

export type TextProps = {
  tag?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'label'
    | 'p'
    | 'div'
    | 'span'
    | 'small'
    | 'strong';
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p1' | 'p2' | 'lb1' | 'lb2' | 'small';
  weight?: 'semibold' | 'bold' | 'normal';
  color?:
    | 'primary'
    | 'secondary'
    | 'red'
    | 'green'
    | 'blue'
    | 'purple'
    | 'yellow'
    | 'primary-blue'
    | 'white'
    | 'black';
  align?: 'left' | 'center' | 'right';
  ellipsis?: boolean;
  wrap?: boolean;
  uppercase?: boolean;
  className?: string;
  style?: Partial<CSSStyleDeclaration>;
  title?: string;
  children?: ReactNode | ReactNode[] | TFunctionResult;
};

export const Text: React.FC<TextProps> = React.memo((props) => {
  const {
    tag = 'div',
    type,
    weight,
    color,
    align,
    ellipsis,
    uppercase,
    wrap,
    className,
    children,
    ...textProps
  } = props;

  return React.createElement(
    tag,
    {
      className: cn(
        s.text,
        s[type],
        weight && s[`weight-${weight}`],
        color && s[`${color}-color`],
        align && `text-${align}`,
        ellipsis && 'text-ellipsis',
        uppercase && 'text-uppercase',
        wrap === true && 'text-wrap',
        wrap === false && 'text-nowrap',
        className
      ),
      ...textProps,
    },
    children as ReactNode | ReactNode[]
  );
});

export type HintProps = {
  text: React.ReactNode;
  type?: 'primary' | 'secondary';
  className?: string;
  maxWidth?: number;
  children?: ReactNode;
};

export const Hint: React.FC<HintProps> = (props) => {
  const { text, className, maxWidth, children, type = 'primary' } = props;

  if (!text) {
    return <>{children}</>;
  }

  const overlayStyle = {
    ...(maxWidth !== undefined && { maxWidth: `${maxWidth}px`, color: '#000' }),
  };

  return (
    <div className={cn(s.hint, className)}>
      <span>{children}</span>
      <Tooltip
        title={text}
        className={cn(s.tooltip, 'text-p2')}
        overlayStyle={overlayStyle}
        overlayInnerStyle={overlayStyle}
        color={'#fff'}
      >
        <span>
          <img src={type === 'primary' ? InfoSvg : InfoLightSvg} alt="info" />
        </span>
      </Tooltip>
    </div>
  );
};
