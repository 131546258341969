import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { ApiPromise, WsProvider } from '@polkadot/api';

import config from 'config';

import * as Sentry from '@sentry/react';

import type { ApiProps } from '../types';
import types from '../types.json';

const ApiContext: React.Context<ApiProps> = React.createContext({} as unknown as ApiProps);

export function useApi(): ApiProps {
  return useContext(ApiContext);
}

let api: ApiPromise;

export { api };

const ApiProvider: React.FC<{ children: ReactNode }> = (props) => {
  const [isApiConnected, setIsApiConnected] = useState(false);
  const [isApiInitialized, setIsApiInitialized] = useState(false);
  const [isApiReady, setIsApiReady] = useState(false);
  const [apiError, setApiError] = useState<null | string>(null);

  const value = useMemo<ApiProps>(
    () => ({
      api,
      apiError,
      isApiConnected,
      isApiInitialized,
      isApiReady,
    }),
    [apiError, isApiConnected, isApiInitialized, isApiReady]
  );

  // initial initialization
  useEffect((): void => {
    const provider = new WsProvider(config.otto.endpoint);

    api = new ApiPromise({
      provider,
      runtime: {
        CatalystApi: [
          {
            methods: {
              query_total_cost: {
                description: 'Get total cost.',
                params: [
                  {
                    name: 'id',
                    type: 'u32',
                  },
                  {
                    name: 'amount',
                    type: 'Balance',
                  },
                  {
                    name: 'required_asset',
                    type: 'AssetId',
                  },
                ],
                type: 'Balance',
              },
            },
            version: 1,
          },
        ],
        CatalystRewardApi: [
          {
            methods: {
              available_rewards: {
                description: 'Get available rewards.',
                params: [
                  {
                    name: 'who',
                    type: 'AccountId',
                  },
                ],
                type: 'Balance',
              },
            },
            version: 1,
          },
        ],
      },
      types,
    });

    api.on('connected', () => setIsApiConnected(true));
    api.on('disconnected', () => setIsApiConnected(false));
    api.on('error', (error: Error) => {
      setApiError(error.message);
      Sentry.captureException(error);
    });
    api.on('ready', (): void => setIsApiReady(true));

    setIsApiInitialized(true);
    console.log('API is ready');
  }, []);

  if (!value.isApiInitialized) {
    return null;
  }

  return (
    <ApiContext.Provider value={value}>
      {/* {isApiReady ? props.children : <AntdSpin size="large" className="pv-24 ph-64" />} */}
      {props.children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
