import React, { useRef, useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Form, FormItemProps, Input, notification } from 'antd';

import config from 'config';

import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { Text } from 'components/typography';
import { register, verifyRecaptchaToken } from 'utils/fetchRequest';

import AuthCard from './AuthCard';
import PasswordRulesChecker, { PasswordRulesCheckerHandler } from './PasswordRulesChecker';
import styles from './s.module.scss';

interface Props {
  isLogin?: boolean;
  onContinue?: (email: string) => void;
}

interface PasswordStatus {
  status: FormItemProps['validateStatus'];
  message: string;
}

const AuthForm: React.FC<Props> = ({ isLogin = false, onContinue }) => {
  const [isLoading, setIsLoading] = useState(false);
  const passwordCheckerRef = React.createRef<PasswordRulesCheckerHandler>();

  const [passwordStatus, setPasswordStatus] = useState<PasswordStatus>({
    status: '',
    message: '',
  });
  const currentPasswordStatus = useRef<PasswordStatus>(passwordStatus);
  const [recaptchaTokenPassed, setRecaptchaTokenPassed] = useState<boolean>(false);
  const { t } = useTranslation();

  const openNotification = (message: string) => {
    notification.open({
      message: message,
      icon: <WarningOutlined style={{ color: 'red' }} />,
    });
  };

  const onFinish = (values: { [x: string]: string }) => {
    setIsLoading(true);
    register(values['email'], values['password'])
      .then((res) => {
        setIsLoading(false);
        const { error, response } = res;
        if (error && response.message && response.message.length > 0) {
          openNotification(response.message || error);
        } else {
          onContinue && onContinue(values['email']);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        openNotification(error);
      });
  };

  const handleRecaptchaToken = (token: string) => {
    verifyRecaptchaToken(token, isLogin ? 'LogIn' : 'SignUp').then(
      (res: { error: string; response: boolean }) => {
        if (res.error && res.error.length > 0) {
          setRecaptchaTokenPassed(false);
        } else {
          setRecaptchaTokenPassed(true);
        }
      }
    );
  };
  const title = isLogin ? t('auth_login_title') : t('auth_signup_title');
  const desc = isLogin ? t('auth_login_desc') : t('auth_signup_desc');

  const switchModeTitle = isLogin ? t('auth_login_to_signup') : t('auth_signup_to_login');

  const switchModeBtn = isLogin ? t('auth_login_to_signup_btn') : t('auth_signup_to_login_btn');

  const handleOnPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const status = passwordCheckerRef.current?.check(e.target.value);

    if (
      status &&
      (currentPasswordStatus.current.status !== status.status ||
        currentPasswordStatus.current.message !== status.message)
    ) {
      currentPasswordStatus.current = status;
      setPasswordStatus(status);
    }
  };

  const handleOnPasswordFocus = () => {
    passwordCheckerRef.current?.showChecker();
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.recaptchav3}>
      <GoogleReCaptcha onVerify={handleRecaptchaToken} />
      <AuthCard title={title} desc={desc}>
        <Form style={{ width: '100%' }} onFinish={onFinish} autoComplete="auto">
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: t('auth_email_address_validation'),
              },
              { required: true, message: t('auth_email_address_required') },
            ]}
          >
            <Input
              className={styles.item}
              size="large"
              placeholder={t('auth_email_address')}
              disabled={isLoading}
            />
          </Form.Item>
          <Form.Item
            name="password"
            {...(isLogin
              ? {}
              : {
                  validateStatus: passwordStatus.status,
                  help: passwordStatus.message,
                })}
          >
            <Input.Password
              className={styles.item}
              size="large"
              placeholder={t('auth_password')}
              disabled={isLoading}
              {...(isLogin
                ? {
                    rules: [{ required: true, message: t('auth_password_required') }],
                  }
                : {
                    onChange: handleOnPasswordChange,
                    onFocus: handleOnPasswordFocus,
                  })}
            />
          </Form.Item>
          <PasswordRulesChecker ref={passwordCheckerRef} />
          <Form.Item>
            <Button
              className={styles.button}
              block
              size="large"
              type="primary"
              htmlType="submit"
              disabled={
                isLoading ||
                !recaptchaTokenPassed ||
                (!isLogin && passwordStatus.status !== 'success')
              }
              icon={isLoading ? <LoadingOutlined /> : null}
            >
              {isLoading ? t('auth_loading') : t('auth_continue')}
            </Button>
          </Form.Item>
        </Form>
        <Text type="p2" className={styles.bottom}>
          {switchModeTitle}
          <span>
            <Link to={'/login'} className={styles.link}>
              {switchModeBtn}
            </Link>
          </span>
        </Text>
      </AuthCard>
    </GoogleReCaptchaProvider>
  );
};

export default AuthForm;
