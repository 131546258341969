import Divider from 'components/divider';
import Grid from 'components/grid';
import AccountMenu from 'layout/account-menu';
import useAuthContext from 'providers/auth-provider';
import ConnectedWallet from 'wallet/components/connected-wallet';

const DashboardNav = () => {
  const { accessToken } = useAuthContext();

  return accessToken ? (
    <Grid flow="col" gap={24} justify="center" align="center">
      <ConnectedWallet />
      <Divider type="vertical" />
      <AccountMenu />
    </Grid>
  ) : (
    <></>
  );
};

export default DashboardNav;
