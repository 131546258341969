import type { Option } from '@polkadot/types';
import type { AssetBalance, AssetId } from '@polkadot/types/interfaces';
import { BN } from '@polkadot/util';

import { useCall } from '../common/useCall';
import { useApi } from 'chain-api/api-provider';
import { useAssets } from 'providers/assets-provider';

export interface BalanceResult {
  assetId: AssetId;
  accountId: string;
  balance: BN;
}

interface Result {
  balances: BalanceResult[];
}

function isOptional(value: AssetBalance | Option<AssetBalance>): value is Option<AssetBalance> {
  return (value as Option<AssetBalance>).isSome || (value as Option<AssetBalance>).isNone;
}

const queryOptions = {
  transform: ([[params], balances]: [
    [[AssetId, string][]],
    (AssetBalance | Option<AssetBalance>)[]
  ]): Result => ({
    balances: params.map(([assetId, accountId], index) => {
      const balance = balances[index];
      const o = isOptional(balance) ? balance.unwrapOr(null) : balance;
      return {
        assetId,
        accountId,
        balance: new BN(o?.balance.toString() ?? 0),
      };
    }),
  }),
  withParamsTransform: true,
};

export default function useBalances(account): BalanceResult[] | [] {
  const { api, isApiReady } = useApi();
  const { assets } = useAssets();
  const query = useCall(
    isApiReady && account && api.query.assets.account.multi,
    [assets.map((a) => [a.id, account])],
    queryOptions
  );

  return (query && account && query.balances) || [];
}
