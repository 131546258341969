import React from 'react';

import cn from 'classnames';

import DashboardNav from 'layout/dashboard-nav';

import Logo from '../logo';
import SelectLanguage from '../select-language';
import styles from './s.module.scss';

const DashboardHeader = () => {
  return (
    <header className={styles.header}>
      <div className={cn(styles.container)}>
        <a className={styles.logo} href={'https://www.ottoblockchain.com/'}>
          <Logo />
        </a>
        <div className={styles.wrapper}>
          <SelectLanguage />
          <div className={styles.control}>
            <DashboardNav />
          </div>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
