import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { InjectedAccount } from '@polkadot/extension-inject/types';
import { Identicon } from '@polkadot/react-identicon';

import cn from 'classnames';

import Button from 'components/button';
import Modal, { ModalProps } from 'components/modal';
import { Text } from 'components/typography';
import SelectedSvg from 'resources/svg/selected.svg';

import s from './s.module.scss';

export type ChangeAccountModalProps = ModalProps & {
  accountList?: InjectedAccount[];
  selectedAccount?: InjectedAccount | null;
  onSelectAccount: (account: InjectedAccount) => void;
  onClose: () => void;
};

interface IAccountItemProps {
  account: InjectedAccount;
  isActive: boolean;
  onClick: () => void;
}

const AccountItem = ({ account, isActive, onClick }: IAccountItemProps) => {
  return (
    <div className={cn(s.accountItemContainer, isActive && s.accountSelected)} onClick={onClick}>
      <img src={SelectedSvg} alt="selected" className={cn(!isActive && s.invisible, 'mr-12')} />

      <Identicon value={account?.address} size={42} theme="substrate" className="mr-12" />

      <Text type="h4" color="primary" weight="bold" className={cn(isActive && s.selectedText)}>
        {account?.name}
      </Text>
    </div>
  );
};

const ChangeAccountModal: React.FC<ChangeAccountModalProps> = ({
  accountList,
  selectedAccount,
  onClose,
  onSelectAccount,
  ...modalProps
}) => {
  const { t } = useTranslation();
  const [account, setAccount] = useState(selectedAccount);

  useEffect(() => {
    if (selectedAccount) {
      setAccount(selectedAccount);
    }
  }, [selectedAccount]);

  const changeAccount = () => {
    account && onSelectAccount(account);
    setAccount(null);
    onClose();
  };

  return (
    <Modal
      title={t('wallet_connect_modal_title')}
      closable
      {...modalProps}
      onCancel={() => {
        setAccount(selectedAccount);
        onClose();
      }}
    >
      <div className={cn('flex flow-row', s.accounts)}>
        {accountList &&
          accountList.map((item) => (
            <AccountItem
              key={item.address}
              onClick={() => setAccount(item)}
              account={item}
              isActive={item.address === account?.address}
            />
          ))}
      </div>
      <div className={s.action}>
        <Button
          type="primary"
          disabled={selectedAccount?.address === account?.address}
          className={s.actionBtn}
          size="large"
          onClick={changeAccount}
        >
          {t('wallet_connect_modal_select_btn')}
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeAccountModal;
