import type { AccountInfoWithProviders, AccountInfoWithRefCount } from '@polkadot/types/interfaces';
import type { AssetId } from '@polkadot/types/interfaces';
import { BN_ONE } from '@polkadot/util';

import { useCall } from '../common/useCall';
import { useApi } from 'chain-api/api-provider';

import { BalanceResult } from './useBalance';

export default function useAccountInfo(account): BalanceResult | null {
  const { api, isApiReady } = useApi();
  const accountInfo = useCall<AccountInfoWithProviders | AccountInfoWithRefCount>(
    isApiReady && account && api.query.system.account,
    account && [account]
  );

  if (accountInfo) {
    return {
      assetId: BN_ONE.toNumber() as unknown as AssetId,
      accountId: account,
      balance: accountInfo?.data.free,
    };
  }

  return null;
}
