import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Card, FormItemProps } from 'antd';

import { t } from 'i18next';

import { CheckOutlined } from '@ant-design/icons';
import { Text } from 'components/typography';

import styles from './s.module.scss';

const validCharacterTypes = ['lowercase', 'uppercase', 'number', 'special'];

export interface PasswordRulesCheckerHandler {
  check: (password: string) => {
    status: FormItemProps['validateStatus'];
    message: string;
  };
  showChecker: () => void;
  hideChecker: () => void;
}

export interface PasswordRulesCheckerProps {
  defaultShow?: boolean;
  show?: boolean;
}

const PasswordRulesChecker = forwardRef<PasswordRulesCheckerHandler, PasswordRulesCheckerProps>(
  (props, ref) => {
    const [status, setStatus] = useState({
      length: false,
      types: false,
      number: false,
      lowercase: false,
      uppercase: false,
      special: false,
    });
    const { defaultShow, show: passedShow } = props;
    const [show, setShow] = useState(defaultShow || false);

    const currentShowStatus = useRef<boolean>(show);

    const updateShowStatus = useCallback((newStatus) => {
      if (newStatus !== currentShowStatus.current) {
        setShow(newStatus);
        currentShowStatus.current = newStatus;
      }
    }, []);

    useEffect(() => {
      updateShowStatus(passedShow);
    }, [passedShow]);

    useImperativeHandle(ref, () => {
      return {
        check: (password) => {
          const newStatus = {
            length: false,
            types: false,
            number: false,
            lowercase: false,
            uppercase: false,
            special: false,
          };
          if (password) {
            newStatus.length = password.length >= 8;
            let matchedTypes = 0;
            newStatus.number = /\d/.test(password);
            newStatus.lowercase = /[a-z]/.test(password);
            newStatus.uppercase = /[A-Z]/.test(password);
            newStatus.special = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
            if (newStatus.number) {
              matchedTypes++;
            }
            if (newStatus.lowercase) {
              matchedTypes++;
            }
            if (newStatus.uppercase) {
              matchedTypes++;
            }
            if (newStatus.special) {
              matchedTypes++;
            }
            if (matchedTypes >= 3) {
              newStatus.types = true;
            }
          }

          setStatus(newStatus);
          return {
            status: newStatus.length && newStatus.types ? 'success' : 'error',
            message: password.length > 0 ? '' : t('auth_password_required'),
          };
        },
        showChecker: () => {
          updateShowStatus(true);
        },
        hideChecker: () => {
          updateShowStatus(false);
        },
      };
    });

    const renderRuleDescription = (rule: string) => {
      const passed = status[rule];
      return (
        <li
          key={rule}
          className={`${styles['password-rule-item']} ${passed ? styles['rule-success'] : ''}`}
        >
          <div className={styles['password-rule-item-icon']}>
            {passed ? <CheckOutlined /> : <span className={styles['password-rule-item-dot']} />}
          </div>
          <Text type="small">{t(`auth_password_format_validation_${rule}_description`)}</Text>
        </li>
      );
    };
    if (!show) {
      return null;
    }
    return (
      <Card bordered style={{ marginBottom: '20px' }}>
        <Text type="small">{t('auth_password_format_validation_title')}:</Text>
        <div>
          <ul>
            {['length', 'types'].map((type) => {
              return renderRuleDescription(type);
            })}
            <ul className={styles['password-character-types']}>
              {validCharacterTypes.map((type) => {
                return renderRuleDescription(type);
              })}
            </ul>
          </ul>
        </div>
      </Card>
    );
  }
);

export default PasswordRulesChecker;
