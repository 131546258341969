import React, { FC, ReactNode, createContext, useContext } from 'react';
import { useMemo } from 'react';

import type { AssetId } from '@polkadot/types/interfaces';

import { useAssetsQuery } from 'generated/graphql';

const LP_TOKEN_ID_CHECK = 4294967296; // 1 << 32

export interface AssetInfo {
  id: AssetId;
  name: string;
  symbol: string;
  decimal: number;
  isLPToken: boolean;
}

type ContextType = {
  assets: AssetInfo[];
  getAssetBySymbol(symbol: string): AssetInfo | undefined;
  getAssetById(id: AssetId): AssetInfo | undefined;
};

const Context = createContext<ContextType>({
  assets: [],
  getAssetBySymbol: (symbol: string) => undefined,
  getAssetById: (id: AssetId) => undefined,
});

export function useAssets(): ContextType {
  return useContext<ContextType>(Context);
}

const AssetsProvider: FC<{ children?: ReactNode }> = (props) => {
  const { loading, error, data } = useAssetsQuery();

  const { children } = props;

  const assets: AssetInfo[] = useMemo(() => {
    if (!data || !data.assets) {
      return [];
    }
    return data.assets.map((asset) => {
      return {
        ...asset,
        decimal: asset.decimals,
        id: Number(asset.assetId) as unknown as AssetId,
        isLPToken: Number(asset.assetId) > LP_TOKEN_ID_CHECK,
      };
    });
  }, [data]);

  const getAssetBySymbol = (symbol: string): AssetInfo | undefined => {
    return assets.find((asset) => asset.symbol === symbol);
  };

  const getAssetById = (id: AssetId): AssetInfo | undefined => {
    return assets.find((asset) => Number(asset.id) === Number(id));
  };

  const value = {
    assets,
    getAssetBySymbol,
    getAssetById,
  };

  // return <Context.Provider value={value}>{assets.length > 0 && children}</Context.Provider>;
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default AssetsProvider;
