import { decodeAddress, encodeAddress } from '@polkadot/keyring';
import { hexToU8a, isHex } from '@polkadot/util';

import * as Sentry from '@sentry/react';

export function shortenAddr(addr: string | undefined, first = 6, last = 4): string | undefined {
  return addr ? [String(addr).slice(0, first), String(addr).slice(-last)].join('...') : undefined;
}

export function shortenName(name: string | undefined): string {
  if (!name) return '';

  return name.length > 13 ? [name.slice(0, 10), name.slice(-3)].join('...') : name;
}

export function validateAddress(address: string) {
  const isOttoAddress = isOttoChainAddress(address);
  if (!isOttoAddress) {
    return false;
  }
  return isValidAddressPolkadotAddress(address);
}

const isValidAddressPolkadotAddress = (address: string) => {
  try {
    encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address));

    return true;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};

const isOttoChainAddress = (address: string) => {
  return address.startsWith('5');
};
