import { FC, ReactNode, createContext, useContext, useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { Spin } from 'antd';

import config from 'config';

import Intercom from '@intercom/messenger-js-sdk';
import { getIntercomHmac } from 'utils/fetchRequest';

import useAuthContext from './auth-provider';
import useFeatureFlagContext, { FeatureFlags } from './feature-flag-provider';
import { useProfileContext } from './profile-provider';

export type IntercomContextType = {
  intercomHmac: string | undefined;
};

const Context = createContext<IntercomContextType>({
  intercomHmac: undefined,
});

export function useIntercomContext(): IntercomContextType {
  return useContext(Context);
}

const fetchIntercomHmac = async (token: any) => {
  const { response, error } = await getIntercomHmac(token);
  if (!error) return response?.hmac;
};

const useUserProfile = () => {
  const { accessToken } = useAuthContext();
  const [intercomHmac, setIntercomHmac] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const { featureFlags } = useFeatureFlagContext();

  const isIntercomEnabled = useMemo(() => {
    return featureFlags.some((flag) => flag.name === FeatureFlags.intercom && flag.enabled);
  }, [featureFlags]);

  useEffect(() => {
    async function fetchIntercom() {
      setLoading(true);
      const hmac = await fetchIntercomHmac(accessToken);
      setIntercomHmac(hmac);
      setLoading(false);
    }
    if (isIntercomEnabled && accessToken) {
      fetchIntercom();
    }
  }, [accessToken, isIntercomEnabled]);

  return { loading, intercomHmac };
};

const IntercomProvider: FC<{ children?: ReactNode }> = (props) => {
  const { children } = props;

  const { loading, intercomHmac } = useUserProfile();

  const { profile } = useProfileContext();
  const { featureFlags } = useFeatureFlagContext();

  const isIntercomEnabled = useMemo(() => {
    return featureFlags.some((flag) => flag.name === FeatureFlags.intercom && flag.enabled);
  }, [featureFlags]);

  if (isIntercomEnabled) {
    Intercom({
      app_id: config.intercom.appId,
      user_id: profile?.authId,
      name: profile?.nickname ?? profile?.email,
      email: profile?.email,
      created_at: new Date().getTime(),
      user_hash: intercomHmac,
    });
  }

  const value: IntercomContextType = {
    intercomHmac,
  };

  return (
    <Context.Provider value={value}>{loading ? <Spin size="large" /> : children}</Context.Provider>
  );
};

export default IntercomProvider;
