import React, { ReactNode } from 'react';

import Card from 'components/card';
import { Text } from 'components/typography';
import OttoLogoSvg from 'resources/svg/otto-logo.svg';

import styles from './s.module.scss';

interface Props {
  children?: ReactNode;
  title?: string;
  desc?: string;
  wide?: boolean;
  smallTitle?: boolean;
}

const AuthCard: React.FC<Props> = ({ children, title, desc, wide = false, smallTitle = false }) => {
  return (
    <div className="flex absolute-center justify-center">
      <Card bordered>
        <div className={`${styles.container} ${wide ? styles.wide : ''}`}>
          <div className={styles.logo}>
            <img src={OttoLogoSvg} alt="Otto" sizes="(max-width 500px) 500px, 1000px" />
          </div>
          {title && (
            <Text type={smallTitle ? 'h3' : 'h1'} className={styles.title}>
              {title}
            </Text>
          )}
          {desc && (
            <Text type="p2" className={styles.desc}>
              {desc}
            </Text>
          )}
          {children}
        </div>
      </Card>
    </div>
  );
};

export default AuthCard;
