/* eslint-disable @typescript-eslint/ban-types */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'antd/lib/button';
import List from 'antd/lib/list';

import cn from 'classnames';

import Grid from 'components/grid';
import Popover from 'components/popover';
import { Text } from 'components/typography';
import useAuthContext from 'providers/auth-provider';
import AccountSvg from 'resources/svg/account.svg';
import LogoutSvg from 'resources/svg/logout.svg';

import s from './s.module.scss';

interface AccountNavItem {
  title: string;
  link?: string;
  action?: Function;
}

const AccountMenu: React.FC = () => {
  const { logout } = useAuthContext();
  const { t } = useTranslation();
  const history = useHistory();

  const items: AccountNavItem[] = [
    {
      title: t('nav_my_account'),
      link: '/user/account',
    },
    {
      title: t('nav_help'),
      link: '/user/verification/overview',
    },
    {
      title: t('nav_log_out'),
      action: logout,
    },
  ];

  const AccountSection = (
    <Popover
      placement="bottom"
      trigger="click"
      noPadding
      className={s.popover}
      content={
        <div className={cn(s.content, 'card')}>
          <List
            itemLayout="horizontal"
            split={false}
            dataSource={items}
            renderItem={(item) => (
              <List.Item
                itemType="button"
                className={s.listItem}
                onClick={() => {
                  if (item.action) {
                    item.action();
                  } else {
                    item.link && history.push(item.link);
                  }
                }}
              >
                <Text type="p2" color="yellow" className={s.navItem}>
                  {item.title}
                  {item.title === t('nav_log_out') && (
                    <img src={LogoutSvg} alt="Otto" sizes="18px, 18px" />
                  )}
                </Text>
              </List.Item>
            )}
          />
        </div>
      }
    >
      <Button type="link" className={s.accountLink}>
        <Grid flow="col" align="center">
          <img src={AccountSvg} alt="Otto" sizes="46px, 46px" />
        </Grid>
      </Button>
    </Popover>
  );

  return (
    <Grid flow="col" gap={24} justify="center">
      {AccountSection}
    </Grid>
  );
};

export default memo(AccountMenu);
