// src/auth/protected-route.js
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Spin } from 'antd';

import useAuthContext from 'providers/auth-provider';

const ProtectedRoute = ({ component: Component, ...args }) => {
  const { isLoading, isAuthenticated } = useAuthContext();

  if (isLoading) {
    return (
      <div className="flex align-center justify-center mb-32  mt-32">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Route
      {...args}
      render={({ location }) =>
        isAuthenticated ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
