import cn from 'classnames';

import { Navigation } from 'types/navigation';

import styles from './s.module.scss';

interface Props {
  navigationItems: Navigation[];
  title: string;
  children: React.ReactNode;
  hideSideNavigation?: boolean;
}

const ProfileContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.profile}>
      <div className={styles.body}>
        <div className={cn(styles.container)}>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
