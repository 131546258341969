import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import Button from 'components/button';
import Modal from 'components/modal';
import { Text } from 'components/typography';

import s from './s.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const SignUpDisabledModal = ({ open, onClose }: IProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onCancel={onClose} closable={false} className={s.sign_up_disabled_modal}>
      <div className={s.content}>
        <Text type="h3" color="primary">
          {t('sign_up_disabled')}
        </Text>

        <div className={classNames('flex', 'flow-row', 'row-gap-16')}>
          <Button type="secondary" onClick={onClose} className={s.btn}>
            {t('btn_close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpDisabledModal;
