import React from 'react';

import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { POLKADOT_EXTENSION_LINK } from 'config';

import ExternalLink from 'components/externalLink';
import Modal, { ModalProps } from 'components/modal';
import { Text } from 'components/typography';

const ExtensionInstallationModal: React.FC<ModalProps> = (props) => {
  const { ...modalProps } = props;

  return (
    <Modal closable={false} width={560} title="Install extension" {...modalProps}>
      <div className="grid flow-row pv-8 ph-8">
        <FontAwesomeIcon icon={faPlug} size="2x" className="mb-32 mh-auto" />
        <Text type="p1" color="secondary" className="mb-32 text-center">
          {`polkadot{.js} extension is not found, please install it.`}
        </Text>
        <ExternalLink href={POLKADOT_EXTENSION_LINK} className="button-primary full-width">
          Install extension
        </ExternalLink>
      </div>
    </Modal>
  );
};

export default ExtensionInstallationModal;
