import React, { ReactNode } from 'react';

import cn from 'classnames';

import styles from './s.module.scss';

interface Props {
  children?: ReactNode;
  bordered?: boolean;
  padding?: number;
}

const Card: React.FC<Props> = (props) => {
  const { children, bordered, padding = 0 } = props;
  return (
    <div
      style={{ padding: padding }}
      className={cn(styles.container, { padding }, bordered ? styles.border : {})}
    >
      {children}
    </div>
  );
};

export default Card;
