import { useState } from 'react';

import { AuthForm } from 'components/auth';
import AuthVerifyEmail from 'components/auth/AuthVerifyEmail';

const SignUpView: React.FC = () => {
  const [email, setEmail] = useState('');
  const [index, setIndex] = useState(0);

  const onNext = (email: string) => {
    setEmail(email);
    setIndex(1);
  };

  switch (index) {
    case 0:
      return <AuthForm onContinue={onNext} />;
    case 1:
      return <AuthVerifyEmail email={email} />;
    default:
      break;
  }
  return <div></div>;
};

export default SignUpView;
