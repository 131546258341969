import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps, matchPath, useLocation, withRouter } from 'react-router-dom';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import Background from 'layout/background';
import DashboardPage from 'layout/dashboard-page';

import Header from '../header';
import styles from './s.module.scss';

const PathsWithoutHeader: string[] = ['/signup', '/login', '/dashboard'];

interface Props extends RouteComponentProps {
  children: React.ReactNode;
}

const NewDashboardRoutes = [
  {
    path: '/dashboard',
    exact: true,
  },
  {
    path: '/staking/:tab',
    exact: false,
  },
  {
    path: '/airdrop',
    exact: true,
  },
  {
    path: '/plug-utility',
    exact: false,
  },
  {
    path: '/plug-catalyst-redemption',
    exact: false,
  },
  {
    path: '/dex/:tab',
    exact: false,
  },
  {
    path: '/address-book/:tab',
    exact: false,
  },
  {
    path: '/swap',
    exact: true,
  },
  {
    path: '/governance',
    exact: false,
  },
];

const Page: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  const matchNewRoute = useMemo(
    () => NewDashboardRoutes.some((route) => matchPath(pathname, route)),
    [pathname]
  );

  const showHeader = !PathsWithoutHeader.includes(pathname);

  if (matchNewRoute) {
    return <DashboardPage>{children}</DashboardPage>;
  }

  return (
    <>
      <Background>
        <div className={styles.page}>
          {showHeader && <Header />}
          <div className={styles.inner}>{children}</div>
          {/* <Footer /> */}
        </div>
      </Background>
    </>
  );
};

export default withRouter(Page);
