import React from 'react';

import Button from 'antd/lib/button';
import AntdModal, { ModalProps as AntdModalProps } from 'antd/lib/modal';

import cn from 'classnames';

import Grid from 'components/grid';
import { Text } from 'components/typography';
import CloseIcon from 'resources/svg/close.svg';

import s from './s.module.scss';

export type ModalProps = AntdModalProps & {
  confirmClose?: boolean;
  confirmText?: React.ReactNode;
  closeIcon?: React.ReactNode;
  onCancel: (e?: React.MouseEvent<HTMLElement>) => void;
};

const Modal: React.FC<ModalProps> = (props) => {
  const {
    className,
    children,
    confirmClose = false,
    confirmText,
    onCancel,
    closeIcon,
    ...modalProps
  } = props;

  const [confirmVisible, showConfirm] = React.useState<boolean>(false);

  function handleCancel() {
    if (confirmClose) {
      showConfirm(true);
    } else {
      onCancel?.();
    }
  }

  return (
    <AntdModal
      zIndex={1000}
      className={cn(s.component, className)}
      open
      centered
      footer={null}
      closeIcon={closeIcon || <img src={CloseIcon} alt="close" />}
      onCancel={handleCancel}
      {...modalProps}
    >
      {children}

      {confirmVisible && (
        <AntdModal
          zIndex={1001}
          className={s.component}
          open
          centered
          footer={null}
          closeIcon={<></>}
          onCancel={() => showConfirm(false)}
        >
          <Grid flow="row" gap={32}>
            <Text type="p2" color="secondary">
              {confirmText}
            </Text>
            <Grid flow="col" justify="space-between">
              <Button type="ghost" onClick={() => showConfirm(false)}>
                No
              </Button>
              <Button type="primary" onClick={onCancel}>
                Yes
              </Button>
            </Grid>
          </Grid>
        </AntdModal>
      )}
    </AntdModal>
  );
};

export default Modal;
