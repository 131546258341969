/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiPromise } from '@polkadot/api';
import { web3AccountsSubscribe, web3Enable } from '@polkadot/extension-dapp';
import type {
  InjectedAccount,
  InjectedAccountWithMeta,
  InjectedExtension,
  Unsubcall,
} from '@polkadot/extension-inject/types';
import { AssetId } from '@polkadot/types/interfaces';

import config from 'config';

import * as Sentry from '@sentry/react';
import GeneralModal from 'components/modal/GeneralModal';
import useAccountInfo from 'hooks/states/useAccountInfo';
import useBalances, { BalanceResult } from 'hooks/states/useBalance';
import { useProfileContext } from 'providers/profile-provider';
import ChangeAccountModal from 'wallet/components/change-account';
import ExtensionInstallationModal from 'wallet/components/extension-installation-modal';
import { validateAddress } from 'wallet/utils';

export type WalletContextType = {
  balances: BalanceResult[];
  accountList?: InjectedAccount[];
  selectedAccount?: InjectedAccount | null;
  extension?: InjectedExtension | null;
  getBalanceByAssetId: (assetId: AssetId) => BalanceResult | undefined;
  changeAccount: () => void;
  connect: () => void;
  disconnect: () => void;
  verifyWalletAddress: () => boolean;
  verifyAttestation: (api: ApiPromise) => Promise<boolean>;
};

export enum WALLET_ACTION_TYPES {
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  UPDATE_CONNECTION_INFO = 'UPDATE_CONNECTION_INFO',
}

const WalletContext = React.createContext<WalletContextType>({
  balances: [],
  accountList: [],
  selectedAccount: undefined,
  extension: undefined,
  changeAccount: () => {},
  connect: () => {},
  disconnect: () => {},
  getBalanceByAssetId: (assetId: AssetId) => undefined,
  verifyWalletAddress: () => {
    return true;
  },
  verifyAttestation: async (api: ApiPromise) => {
    return true;
  },
});

export function useWallet(): WalletContextType {
  return React.useContext<WalletContextType>(WalletContext);
}

const WalletProvider: React.FC<{ children?: ReactNode }> = (props) => {
  const { t } = useTranslation();

  const [extension, setExtension] = useState<InjectedExtension | null>();
  const [accountList, setAccountList] = useState<InjectedAccount[]>([]);
  const [accountInfo, setAccountInfo] = useState<InjectedAccount | null>();
  const [openModal, setOpenModal] = useState(false);
  const [showChangeAccountModal, setShowChangeAccountModal] = useState<boolean>(false);

  const assetBalances = useBalances(accountInfo?.address);
  const baseToken = useAccountInfo(accountInfo?.address);
  const { profile } = useProfileContext();
  const unsubscribe = useRef<Unsubcall>();

  const balances = useMemo(() => {
    if (baseToken) {
      const ottoId = config.assets.otto;
      const filterdAssets = assetBalances.filter(
        (item) => item.assetId !== (ottoId as unknown as AssetId)
      );
      return [baseToken, ...filterdAssets];
    }
    return [];
  }, [assetBalances, baseToken]);

  const getBalanceByAssetId = useCallback(
    (assetId: AssetId) => {
      return balances.find((item) => item.assetId === assetId);
    },
    [balances]
  );

  useEffect(() => {
    const getInjected: () => void = async () => {
      const extensions = await web3Enable(config.app.name);
      const extension = extensions.find(({ name }) => name === 'polkadot-js') || null;

      if (!extension) {
        return;
      }
      setExtension(extension);
      unsubscribe.current = await web3AccountsSubscribe((accounts: InjectedAccountWithMeta[]) => {
        const injectedAccountsWithMeta: InjectedAccountWithMeta[] = accounts.filter((item) => {
          const { address } = item;
          return validateAddress(address);
        });
        const injectedAccounts = injectedAccountsWithMeta.map((item) => {
          const { address, meta, type } = item;
          return {
            address,
            ...meta,
            type,
          };
        });
        setAccountList(injectedAccounts);
        try {
          const account = window.sessionStorage.getItem('wallet');
          if (account) {
            const accountInfo = JSON.parse(account);
            if (
              injectedAccounts.findIndex(
                (injectAccount) => injectAccount.address === accountInfo.address
              ) !== -1
            ) {
              setAccountInfo(accountInfo);
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          Sentry.captureException(error);
        }
      });
    };

    getInjected();
  }, []);

  const connect = () => {
    const connectAccount =
      accountList.find((account) => account.address === profile?.ottoWalletAddress) ||
      accountList[0];
    setAccountInfo(connectAccount);
    window.sessionStorage.setItem('wallet', JSON.stringify(connectAccount));
  };

  const changeAccount = () => {
    setShowChangeAccountModal(true);
  };

  const verifyWalletAddress = () => {
    if (accountInfo?.address !== profile?.ottoWalletAddress) {
      setOpenModal(true);
      return false;
    }

    return true;
  };

  const verifyAttestation = async (api: ApiPromise) => {
    const result = await api.query.attestation.topics([
      accountInfo?.address,
      config.otto.multisigAddress,
    ]);
    const topics = result.toHuman() as string[];
    if (topics.length === 0) {
      setOpenModal(true);
      return false;
    }
    return true;
  };

  const onSelectAccount = (newAccount: InjectedAccount) => {
    setShowChangeAccountModal(false);
    setAccountInfo(newAccount);
    window.sessionStorage.setItem('wallet', JSON.stringify(newAccount));
  };

  const onCloseChangeAccountModal = () => {
    setShowChangeAccountModal(false);
  };

  const value = {
    balances,
    accountList,
    selectedAccount: accountInfo,
    extension,
    changeAccount,
    connect,
    disconnect: () => {
      unsubscribe?.current?.();
      window.sessionStorage.removeItem('wallet');
      setAccountInfo(null);
    },
    getBalanceByAssetId,
    verifyWalletAddress,
    verifyAttestation,
  };

  return (
    <WalletContext.Provider value={value}>
      {!extension && <ExtensionInstallationModal onCancel={() => {}} />}
      {props.children}
      {/* {baseToken ? props.children : <AntdSpin size="large" className="pv-24 ph-64" />} */}
      <GeneralModal
        type="failure"
        title={t('wallet_bound_not_correct_title')}
        note={t('wallet_bound_not_correct_note')}
        onCancel={() => setOpenModal(false)}
        open={openModal}
        buttonLabel={t('btn_connect')}
        onButtonClick={() => {
          setOpenModal(false);
          changeAccount();
        }}
      />
      <ChangeAccountModal
        open={showChangeAccountModal}
        accountList={accountList}
        selectedAccount={accountInfo}
        onSelectAccount={onSelectAccount}
        onClose={onCloseChangeAccountModal}
        onCancel={onCloseChangeAccountModal}
      />
    </WalletContext.Provider>
  );
};

export default WalletProvider;
