import React from 'react';
import { withTranslation } from 'react-i18next';

import AntdNotification from 'antd/lib/notification';
import AntdResult from 'antd/lib/result';

import Grid from 'components/grid';
import { Text } from 'components/typography';
import ErrorIcon from 'resources/svg/error-500.svg';

import s from './s.module.scss';

type State = {
  error?: Error;
};

class ErrorBoundary extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  componentDidCatch(error: Error) {
    this.setState({
      error,
    });

    AntdNotification.error({
      message: error.message,
    });
  }

  handleRefresh = () => {
    window.location.href = `${window.location.href}`;
  };

  render() {
    if (this.state.error) {
      return (
        <AntdResult
          className={s.container}
          icon={
            <div className={s.icon}>
              <img src={ErrorIcon} alt="error" />
            </div>
          }
          title={
            <Text type="h2" color="primary">
              {this.props.t('error_page_title')}
            </Text>
          }
          subTitle={
            <Grid flow="col" className="mt-16">
              <Grid flow="row">
                <Text type="p2" color="primary">
                  {this.props.t('error_page_description')}
                </Text>
              </Grid>
            </Grid>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
