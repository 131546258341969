import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  DateTime: any;
};

export type Asset = {
  __typename?: 'Asset';
  assetId: Scalars['BigInt'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  decimals: Scalars['Int'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isFrozen: Scalars['Boolean'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
};

export type AssetEdge = {
  __typename?: 'AssetEdge';
  cursor: Scalars['String'];
  node: Asset;
};

export enum AssetOrderByInput {
  AssetIdAsc = 'assetId_ASC',
  AssetIdDesc = 'assetId_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  DecimalsAsc = 'decimals_ASC',
  DecimalsDesc = 'decimals_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsFrozenAsc = 'isFrozen_ASC',
  IsFrozenDesc = 'isFrozen_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SymbolAsc = 'symbol_ASC',
  SymbolDesc = 'symbol_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
}

export type AssetTransferHistoryConnectionModel = {
  __typename?: 'AssetTransferHistoryConnectionModel';
  items: Array<AssetTransferHistoryModel>;
  page: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type AssetTransferHistoryModel = {
  __typename?: 'AssetTransferHistoryModel';
  amount?: Maybe<Scalars['BigInt']>;
  assetId?: Maybe<Scalars['String']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Float'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['BigInt']>;
  from?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  to?: Maybe<Scalars['String']>;
  txIndex?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type AssetWhereInput = {
  AND?: InputMaybe<Array<AssetWhereInput>>;
  OR?: InputMaybe<Array<AssetWhereInput>>;
  assetId_eq?: InputMaybe<Scalars['BigInt']>;
  assetId_gt?: InputMaybe<Scalars['BigInt']>;
  assetId_gte?: InputMaybe<Scalars['BigInt']>;
  assetId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assetId_isNull?: InputMaybe<Scalars['Boolean']>;
  assetId_lt?: InputMaybe<Scalars['BigInt']>;
  assetId_lte?: InputMaybe<Scalars['BigInt']>;
  assetId_not_eq?: InputMaybe<Scalars['BigInt']>;
  assetId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  decimals_eq?: InputMaybe<Scalars['Int']>;
  decimals_gt?: InputMaybe<Scalars['Int']>;
  decimals_gte?: InputMaybe<Scalars['Int']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']>>;
  decimals_isNull?: InputMaybe<Scalars['Boolean']>;
  decimals_lt?: InputMaybe<Scalars['Int']>;
  decimals_lte?: InputMaybe<Scalars['Int']>;
  decimals_not_eq?: InputMaybe<Scalars['Int']>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  isFrozen_eq?: InputMaybe<Scalars['Boolean']>;
  isFrozen_isNull?: InputMaybe<Scalars['Boolean']>;
  isFrozen_not_eq?: InputMaybe<Scalars['Boolean']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_containsInsensitive?: InputMaybe<Scalars['String']>;
  name_endsWith?: InputMaybe<Scalars['String']>;
  name_eq?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_isNull?: InputMaybe<Scalars['Boolean']>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  name_not_endsWith?: InputMaybe<Scalars['String']>;
  name_not_eq?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_startsWith?: InputMaybe<Scalars['String']>;
  name_startsWith?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_containsInsensitive?: InputMaybe<Scalars['String']>;
  symbol_endsWith?: InputMaybe<Scalars['String']>;
  symbol_eq?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_isNull?: InputMaybe<Scalars['Boolean']>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  symbol_not_endsWith?: InputMaybe<Scalars['String']>;
  symbol_not_eq?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_startsWith?: InputMaybe<Scalars['String']>;
  symbol_startsWith?: InputMaybe<Scalars['String']>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type AssetsConnection = {
  __typename?: 'AssetsConnection';
  edges: Array<AssetEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BaseConnectionModel = {
  __typename?: 'BaseConnectionModel';
  page: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type BaseEventModel = {
  __typename?: 'BaseEventModel';
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Float'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Float']>;
};

export type CatalystTransactionConnectionModel = {
  __typename?: 'CatalystTransactionConnectionModel';
  items: Array<CatalystTransactionModel>;
  page: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type CatalystTransactionModel = {
  __typename?: 'CatalystTransactionModel';
  amount?: Maybe<Scalars['BigInt']>;
  assetId?: Maybe<Scalars['BigInt']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Float'];
  epochId?: Maybe<Scalars['BigInt']>;
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  signer?: Maybe<Scalars['String']>;
  stakingPoolIndex?: Maybe<Scalars['BigInt']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Float']>;
};

export type CatalystTransferHistoriesConnection = {
  __typename?: 'CatalystTransferHistoriesConnection';
  edges: Array<CatalystTransferHistoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CatalystTransferHistory = {
  __typename?: 'CatalystTransferHistory';
  amount?: Maybe<Scalars['BigInt']>;
  assetId?: Maybe<Scalars['BigInt']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  epochId?: Maybe<Scalars['BigInt']>;
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  signer?: Maybe<Scalars['String']>;
  stakingPoolIndex?: Maybe<Scalars['BigInt']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
};

export type CatalystTransferHistoryEdge = {
  __typename?: 'CatalystTransferHistoryEdge';
  cursor: Scalars['String'];
  node: CatalystTransferHistory;
};

export enum CatalystTransferHistoryOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  AssetIdAsc = 'assetId_ASC',
  AssetIdDesc = 'assetId_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  EpochIdAsc = 'epochId_ASC',
  EpochIdDesc = 'epochId_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MethodAsc = 'method_ASC',
  MethodDesc = 'method_DESC',
  ModuleAsc = 'module_ASC',
  ModuleDesc = 'module_DESC',
  OwnerAsc = 'owner_ASC',
  OwnerDesc = 'owner_DESC',
  SignerAsc = 'signer_ASC',
  SignerDesc = 'signer_DESC',
  StakingPoolIndexAsc = 'stakingPoolIndex_ASC',
  StakingPoolIndexDesc = 'stakingPoolIndex_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
}

export type CatalystTransferHistoryWhereInput = {
  AND?: InputMaybe<Array<CatalystTransferHistoryWhereInput>>;
  OR?: InputMaybe<Array<CatalystTransferHistoryWhereInput>>;
  amount_eq?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_isNull?: InputMaybe<Scalars['Boolean']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_not_eq?: InputMaybe<Scalars['BigInt']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assetId_eq?: InputMaybe<Scalars['BigInt']>;
  assetId_gt?: InputMaybe<Scalars['BigInt']>;
  assetId_gte?: InputMaybe<Scalars['BigInt']>;
  assetId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assetId_isNull?: InputMaybe<Scalars['Boolean']>;
  assetId_lt?: InputMaybe<Scalars['BigInt']>;
  assetId_lte?: InputMaybe<Scalars['BigInt']>;
  assetId_not_eq?: InputMaybe<Scalars['BigInt']>;
  assetId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  epochId_eq?: InputMaybe<Scalars['BigInt']>;
  epochId_gt?: InputMaybe<Scalars['BigInt']>;
  epochId_gte?: InputMaybe<Scalars['BigInt']>;
  epochId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  epochId_isNull?: InputMaybe<Scalars['Boolean']>;
  epochId_lt?: InputMaybe<Scalars['BigInt']>;
  epochId_lte?: InputMaybe<Scalars['BigInt']>;
  epochId_not_eq?: InputMaybe<Scalars['BigInt']>;
  epochId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  method_contains?: InputMaybe<Scalars['String']>;
  method_containsInsensitive?: InputMaybe<Scalars['String']>;
  method_endsWith?: InputMaybe<Scalars['String']>;
  method_eq?: InputMaybe<Scalars['String']>;
  method_gt?: InputMaybe<Scalars['String']>;
  method_gte?: InputMaybe<Scalars['String']>;
  method_in?: InputMaybe<Array<Scalars['String']>>;
  method_isNull?: InputMaybe<Scalars['Boolean']>;
  method_lt?: InputMaybe<Scalars['String']>;
  method_lte?: InputMaybe<Scalars['String']>;
  method_not_contains?: InputMaybe<Scalars['String']>;
  method_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  method_not_endsWith?: InputMaybe<Scalars['String']>;
  method_not_eq?: InputMaybe<Scalars['String']>;
  method_not_in?: InputMaybe<Array<Scalars['String']>>;
  method_not_startsWith?: InputMaybe<Scalars['String']>;
  method_startsWith?: InputMaybe<Scalars['String']>;
  module_contains?: InputMaybe<Scalars['String']>;
  module_containsInsensitive?: InputMaybe<Scalars['String']>;
  module_endsWith?: InputMaybe<Scalars['String']>;
  module_eq?: InputMaybe<Scalars['String']>;
  module_gt?: InputMaybe<Scalars['String']>;
  module_gte?: InputMaybe<Scalars['String']>;
  module_in?: InputMaybe<Array<Scalars['String']>>;
  module_isNull?: InputMaybe<Scalars['Boolean']>;
  module_lt?: InputMaybe<Scalars['String']>;
  module_lte?: InputMaybe<Scalars['String']>;
  module_not_contains?: InputMaybe<Scalars['String']>;
  module_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  module_not_endsWith?: InputMaybe<Scalars['String']>;
  module_not_eq?: InputMaybe<Scalars['String']>;
  module_not_in?: InputMaybe<Array<Scalars['String']>>;
  module_not_startsWith?: InputMaybe<Scalars['String']>;
  module_startsWith?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_containsInsensitive?: InputMaybe<Scalars['String']>;
  owner_endsWith?: InputMaybe<Scalars['String']>;
  owner_eq?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_isNull?: InputMaybe<Scalars['Boolean']>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  owner_not_endsWith?: InputMaybe<Scalars['String']>;
  owner_not_eq?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_startsWith?: InputMaybe<Scalars['String']>;
  owner_startsWith?: InputMaybe<Scalars['String']>;
  signer_contains?: InputMaybe<Scalars['String']>;
  signer_containsInsensitive?: InputMaybe<Scalars['String']>;
  signer_endsWith?: InputMaybe<Scalars['String']>;
  signer_eq?: InputMaybe<Scalars['String']>;
  signer_gt?: InputMaybe<Scalars['String']>;
  signer_gte?: InputMaybe<Scalars['String']>;
  signer_in?: InputMaybe<Array<Scalars['String']>>;
  signer_isNull?: InputMaybe<Scalars['Boolean']>;
  signer_lt?: InputMaybe<Scalars['String']>;
  signer_lte?: InputMaybe<Scalars['String']>;
  signer_not_contains?: InputMaybe<Scalars['String']>;
  signer_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  signer_not_endsWith?: InputMaybe<Scalars['String']>;
  signer_not_eq?: InputMaybe<Scalars['String']>;
  signer_not_in?: InputMaybe<Array<Scalars['String']>>;
  signer_not_startsWith?: InputMaybe<Scalars['String']>;
  signer_startsWith?: InputMaybe<Scalars['String']>;
  stakingPoolIndex_eq?: InputMaybe<Scalars['BigInt']>;
  stakingPoolIndex_gt?: InputMaybe<Scalars['BigInt']>;
  stakingPoolIndex_gte?: InputMaybe<Scalars['BigInt']>;
  stakingPoolIndex_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakingPoolIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  stakingPoolIndex_lt?: InputMaybe<Scalars['BigInt']>;
  stakingPoolIndex_lte?: InputMaybe<Scalars['BigInt']>;
  stakingPoolIndex_not_eq?: InputMaybe<Scalars['BigInt']>;
  stakingPoolIndex_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type CatalystVoucherAirdropModel = {
  __typename?: 'CatalystVoucherAirdropModel';
  address?: Maybe<Scalars['String']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Float'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  plugAmount?: Maybe<Scalars['BigInt']>;
  shareAmount?: Maybe<Scalars['BigInt']>;
  success?: Maybe<Scalars['Boolean']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Float']>;
};

export type CatalystVoucherRedeemedTransactionConnectionModel = {
  __typename?: 'CatalystVoucherRedeemedTransactionConnectionModel';
  items: Array<CatalystVoucherRedeemedTransactionModel>;
  page: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type CatalystVoucherRedeemedTransactionModel = {
  __typename?: 'CatalystVoucherRedeemedTransactionModel';
  address?: Maybe<Scalars['String']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Float'];
  cataVoucherAmount?: Maybe<Scalars['BigInt']>;
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Float']>;
};

export type DexHistoriesConnection = {
  __typename?: 'DexHistoriesConnection';
  edges: Array<DexHistoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DexHistory = {
  __typename?: 'DexHistory';
  account: Scalars['String'];
  asset0: Scalars['BigInt'];
  asset0Amount: Scalars['BigInt'];
  asset1: Scalars['BigInt'];
  asset1Amount: Scalars['BigInt'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  share?: Maybe<Scalars['BigInt']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type DexHistoryConnectionModel = {
  __typename?: 'DexHistoryConnectionModel';
  items: Array<DexHistoryModel>;
  page: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type DexHistoryEdge = {
  __typename?: 'DexHistoryEdge';
  cursor: Scalars['String'];
  node: DexHistory;
};

export type DexHistoryModel = {
  __typename?: 'DexHistoryModel';
  account?: Maybe<Scalars['String']>;
  asset?: Maybe<Scalars['BigInt']>;
  asset0?: Maybe<Scalars['BigInt']>;
  asset0Amount?: Maybe<Scalars['BigInt']>;
  asset1?: Maybe<Scalars['BigInt']>;
  asset1Amount?: Maybe<Scalars['BigInt']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Float'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  share?: Maybe<Scalars['BigInt']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export enum DexHistoryOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  Asset0AmountAsc = 'asset0Amount_ASC',
  Asset0AmountDesc = 'asset0Amount_DESC',
  Asset0Asc = 'asset0_ASC',
  Asset0Desc = 'asset0_DESC',
  Asset1AmountAsc = 'asset1Amount_ASC',
  Asset1AmountDesc = 'asset1Amount_DESC',
  Asset1Asc = 'asset1_ASC',
  Asset1Desc = 'asset1_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ShareAsc = 'share_ASC',
  ShareDesc = 'share_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum DexHistoryType {
  AddLiquidity = 'AddLiquidity',
  All = 'All',
  RemoveLiquidity = 'RemoveLiquidity',
}

export type DexHistoryWhereInput = {
  AND?: InputMaybe<Array<DexHistoryWhereInput>>;
  OR?: InputMaybe<Array<DexHistoryWhereInput>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_containsInsensitive?: InputMaybe<Scalars['String']>;
  account_endsWith?: InputMaybe<Scalars['String']>;
  account_eq?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_isNull?: InputMaybe<Scalars['Boolean']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  account_not_endsWith?: InputMaybe<Scalars['String']>;
  account_not_eq?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_startsWith?: InputMaybe<Scalars['String']>;
  account_startsWith?: InputMaybe<Scalars['String']>;
  asset0Amount_eq?: InputMaybe<Scalars['BigInt']>;
  asset0Amount_gt?: InputMaybe<Scalars['BigInt']>;
  asset0Amount_gte?: InputMaybe<Scalars['BigInt']>;
  asset0Amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset0Amount_isNull?: InputMaybe<Scalars['Boolean']>;
  asset0Amount_lt?: InputMaybe<Scalars['BigInt']>;
  asset0Amount_lte?: InputMaybe<Scalars['BigInt']>;
  asset0Amount_not_eq?: InputMaybe<Scalars['BigInt']>;
  asset0Amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset0_eq?: InputMaybe<Scalars['BigInt']>;
  asset0_gt?: InputMaybe<Scalars['BigInt']>;
  asset0_gte?: InputMaybe<Scalars['BigInt']>;
  asset0_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset0_isNull?: InputMaybe<Scalars['Boolean']>;
  asset0_lt?: InputMaybe<Scalars['BigInt']>;
  asset0_lte?: InputMaybe<Scalars['BigInt']>;
  asset0_not_eq?: InputMaybe<Scalars['BigInt']>;
  asset0_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset1Amount_eq?: InputMaybe<Scalars['BigInt']>;
  asset1Amount_gt?: InputMaybe<Scalars['BigInt']>;
  asset1Amount_gte?: InputMaybe<Scalars['BigInt']>;
  asset1Amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset1Amount_isNull?: InputMaybe<Scalars['Boolean']>;
  asset1Amount_lt?: InputMaybe<Scalars['BigInt']>;
  asset1Amount_lte?: InputMaybe<Scalars['BigInt']>;
  asset1Amount_not_eq?: InputMaybe<Scalars['BigInt']>;
  asset1Amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset1_eq?: InputMaybe<Scalars['BigInt']>;
  asset1_gt?: InputMaybe<Scalars['BigInt']>;
  asset1_gte?: InputMaybe<Scalars['BigInt']>;
  asset1_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset1_isNull?: InputMaybe<Scalars['Boolean']>;
  asset1_lt?: InputMaybe<Scalars['BigInt']>;
  asset1_lte?: InputMaybe<Scalars['BigInt']>;
  asset1_not_eq?: InputMaybe<Scalars['BigInt']>;
  asset1_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  share_eq?: InputMaybe<Scalars['BigInt']>;
  share_gt?: InputMaybe<Scalars['BigInt']>;
  share_gte?: InputMaybe<Scalars['BigInt']>;
  share_in?: InputMaybe<Array<Scalars['BigInt']>>;
  share_isNull?: InputMaybe<Scalars['Boolean']>;
  share_lt?: InputMaybe<Scalars['BigInt']>;
  share_lte?: InputMaybe<Scalars['BigInt']>;
  share_not_eq?: InputMaybe<Scalars['BigInt']>;
  share_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_containsInsensitive?: InputMaybe<Scalars['String']>;
  type_endsWith?: InputMaybe<Scalars['String']>;
  type_eq?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_isNull?: InputMaybe<Scalars['Boolean']>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  type_not_endsWith?: InputMaybe<Scalars['String']>;
  type_not_eq?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_startsWith?: InputMaybe<Scalars['String']>;
  type_startsWith?: InputMaybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type PlugCataDeposit = {
  __typename?: 'PlugCataDeposit';
  address: Scalars['String'];
  amount: Scalars['BigInt'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  success: Scalars['Boolean'];
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
};

export type PlugCataDepositEdge = {
  __typename?: 'PlugCataDepositEdge';
  cursor: Scalars['String'];
  node: PlugCataDeposit;
};

export enum PlugCataDepositOrderByInput {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SuccessAsc = 'success_ASC',
  SuccessDesc = 'success_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
}

export type PlugCataDepositWhereInput = {
  AND?: InputMaybe<Array<PlugCataDepositWhereInput>>;
  OR?: InputMaybe<Array<PlugCataDepositWhereInput>>;
  address_contains?: InputMaybe<Scalars['String']>;
  address_containsInsensitive?: InputMaybe<Scalars['String']>;
  address_endsWith?: InputMaybe<Scalars['String']>;
  address_eq?: InputMaybe<Scalars['String']>;
  address_gt?: InputMaybe<Scalars['String']>;
  address_gte?: InputMaybe<Scalars['String']>;
  address_in?: InputMaybe<Array<Scalars['String']>>;
  address_isNull?: InputMaybe<Scalars['Boolean']>;
  address_lt?: InputMaybe<Scalars['String']>;
  address_lte?: InputMaybe<Scalars['String']>;
  address_not_contains?: InputMaybe<Scalars['String']>;
  address_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  address_not_endsWith?: InputMaybe<Scalars['String']>;
  address_not_eq?: InputMaybe<Scalars['String']>;
  address_not_in?: InputMaybe<Array<Scalars['String']>>;
  address_not_startsWith?: InputMaybe<Scalars['String']>;
  address_startsWith?: InputMaybe<Scalars['String']>;
  amount_eq?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_isNull?: InputMaybe<Scalars['Boolean']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_not_eq?: InputMaybe<Scalars['BigInt']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  success_eq?: InputMaybe<Scalars['Boolean']>;
  success_isNull?: InputMaybe<Scalars['Boolean']>;
  success_not_eq?: InputMaybe<Scalars['Boolean']>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type PlugCataDepositsConnection = {
  __typename?: 'PlugCataDepositsConnection';
  edges: Array<PlugCataDepositEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PlugCataPaidOut = {
  __typename?: 'PlugCataPaidOut';
  address: Scalars['String'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  shareAmount: Scalars['BigInt'];
  success: Scalars['Boolean'];
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
};

export type PlugCataPaidOutEdge = {
  __typename?: 'PlugCataPaidOutEdge';
  cursor: Scalars['String'];
  node: PlugCataPaidOut;
};

export enum PlugCataPaidOutOrderByInput {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ShareAmountAsc = 'shareAmount_ASC',
  ShareAmountDesc = 'shareAmount_DESC',
  SuccessAsc = 'success_ASC',
  SuccessDesc = 'success_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
}

export type PlugCataPaidOutWhereInput = {
  AND?: InputMaybe<Array<PlugCataPaidOutWhereInput>>;
  OR?: InputMaybe<Array<PlugCataPaidOutWhereInput>>;
  address_contains?: InputMaybe<Scalars['String']>;
  address_containsInsensitive?: InputMaybe<Scalars['String']>;
  address_endsWith?: InputMaybe<Scalars['String']>;
  address_eq?: InputMaybe<Scalars['String']>;
  address_gt?: InputMaybe<Scalars['String']>;
  address_gte?: InputMaybe<Scalars['String']>;
  address_in?: InputMaybe<Array<Scalars['String']>>;
  address_isNull?: InputMaybe<Scalars['Boolean']>;
  address_lt?: InputMaybe<Scalars['String']>;
  address_lte?: InputMaybe<Scalars['String']>;
  address_not_contains?: InputMaybe<Scalars['String']>;
  address_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  address_not_endsWith?: InputMaybe<Scalars['String']>;
  address_not_eq?: InputMaybe<Scalars['String']>;
  address_not_in?: InputMaybe<Array<Scalars['String']>>;
  address_not_startsWith?: InputMaybe<Scalars['String']>;
  address_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  shareAmount_eq?: InputMaybe<Scalars['BigInt']>;
  shareAmount_gt?: InputMaybe<Scalars['BigInt']>;
  shareAmount_gte?: InputMaybe<Scalars['BigInt']>;
  shareAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shareAmount_isNull?: InputMaybe<Scalars['Boolean']>;
  shareAmount_lt?: InputMaybe<Scalars['BigInt']>;
  shareAmount_lte?: InputMaybe<Scalars['BigInt']>;
  shareAmount_not_eq?: InputMaybe<Scalars['BigInt']>;
  shareAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  success_eq?: InputMaybe<Scalars['Boolean']>;
  success_isNull?: InputMaybe<Scalars['Boolean']>;
  success_not_eq?: InputMaybe<Scalars['Boolean']>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type PlugCataPaidOutsConnection = {
  __typename?: 'PlugCataPaidOutsConnection';
  edges: Array<PlugCataPaidOutEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PlugCataRedeem = {
  __typename?: 'PlugCataRedeem';
  address: Scalars['String'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  cataVoucherAmount: Scalars['BigInt'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  success: Scalars['Boolean'];
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
};

export type PlugCataRedeemEdge = {
  __typename?: 'PlugCataRedeemEdge';
  cursor: Scalars['String'];
  node: PlugCataRedeem;
};

export enum PlugCataRedeemOrderByInput {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  CataVoucherAmountAsc = 'cataVoucherAmount_ASC',
  CataVoucherAmountDesc = 'cataVoucherAmount_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SuccessAsc = 'success_ASC',
  SuccessDesc = 'success_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
}

export type PlugCataRedeemWhereInput = {
  AND?: InputMaybe<Array<PlugCataRedeemWhereInput>>;
  OR?: InputMaybe<Array<PlugCataRedeemWhereInput>>;
  address_contains?: InputMaybe<Scalars['String']>;
  address_containsInsensitive?: InputMaybe<Scalars['String']>;
  address_endsWith?: InputMaybe<Scalars['String']>;
  address_eq?: InputMaybe<Scalars['String']>;
  address_gt?: InputMaybe<Scalars['String']>;
  address_gte?: InputMaybe<Scalars['String']>;
  address_in?: InputMaybe<Array<Scalars['String']>>;
  address_isNull?: InputMaybe<Scalars['Boolean']>;
  address_lt?: InputMaybe<Scalars['String']>;
  address_lte?: InputMaybe<Scalars['String']>;
  address_not_contains?: InputMaybe<Scalars['String']>;
  address_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  address_not_endsWith?: InputMaybe<Scalars['String']>;
  address_not_eq?: InputMaybe<Scalars['String']>;
  address_not_in?: InputMaybe<Array<Scalars['String']>>;
  address_not_startsWith?: InputMaybe<Scalars['String']>;
  address_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  cataVoucherAmount_eq?: InputMaybe<Scalars['BigInt']>;
  cataVoucherAmount_gt?: InputMaybe<Scalars['BigInt']>;
  cataVoucherAmount_gte?: InputMaybe<Scalars['BigInt']>;
  cataVoucherAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cataVoucherAmount_isNull?: InputMaybe<Scalars['Boolean']>;
  cataVoucherAmount_lt?: InputMaybe<Scalars['BigInt']>;
  cataVoucherAmount_lte?: InputMaybe<Scalars['BigInt']>;
  cataVoucherAmount_not_eq?: InputMaybe<Scalars['BigInt']>;
  cataVoucherAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  success_eq?: InputMaybe<Scalars['Boolean']>;
  success_isNull?: InputMaybe<Scalars['Boolean']>;
  success_not_eq?: InputMaybe<Scalars['Boolean']>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type PlugCataRedeemsConnection = {
  __typename?: 'PlugCataRedeemsConnection';
  edges: Array<PlugCataRedeemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PlugCatalystDepositConnectionModel = {
  __typename?: 'PlugCatalystDepositConnectionModel';
  items: Array<PlugCatalystDepositModel>;
  page: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type PlugCatalystDepositModel = {
  __typename?: 'PlugCatalystDepositModel';
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['BigInt']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Float'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  success?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Float']>;
};

export type Proposal = {
  __typename?: 'Proposal';
  account: Scalars['String'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  method: Scalars['String'];
  module: Scalars['String'];
  parameters?: Maybe<Scalars['String']>;
  proposalHash: Scalars['String'];
  proposalIndex?: Maybe<Scalars['Int']>;
  status: ProposalStatus;
  threshold?: Maybe<Scalars['Int']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
  type: ProposalType;
};

export type ProposalConnectionModel = {
  __typename?: 'ProposalConnectionModel';
  items: Array<ProposalModel>;
  page: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export enum ProposalCouncilType {
  FinancialCommittee = 'FinancialCommittee',
  GeneralCommittee = 'GeneralCommittee',
}

export type ProposalEdge = {
  __typename?: 'ProposalEdge';
  cursor: Scalars['String'];
  node: Proposal;
};

export enum ProposalExecutionResult {
  Error = 'Error',
  Ok = 'Ok',
}

export type ProposalHistoriesConnection = {
  __typename?: 'ProposalHistoriesConnection';
  edges: Array<ProposalHistoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProposalHistory = {
  __typename?: 'ProposalHistory';
  account?: Maybe<Scalars['String']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  executedResult?: Maybe<ProposalExecutionResult>;
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['Int']>;
  parameters?: Maybe<Scalars['String']>;
  proposalHash: Scalars['String'];
  proposalIndex?: Maybe<Scalars['Int']>;
  status: ProposalStatus;
  threshold?: Maybe<Scalars['Int']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
  type: ProposalType;
  yes?: Maybe<Scalars['Int']>;
};

export type ProposalHistoryEdge = {
  __typename?: 'ProposalHistoryEdge';
  cursor: Scalars['String'];
  node: ProposalHistory;
};

export type ProposalHistoryModel = {
  __typename?: 'ProposalHistoryModel';
  account?: Maybe<Scalars['String']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Float'];
  executedResult?: Maybe<ProposalExecutionResult>;
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['Float']>;
  parameters?: Maybe<Scalars['String']>;
  proposalHash: Scalars['String'];
  proposalIndex?: Maybe<Scalars['Float']>;
  status: ProposalStatus;
  threshold?: Maybe<Scalars['Float']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Float']>;
  type: ProposalType;
  yes?: Maybe<Scalars['Float']>;
};

export enum ProposalHistoryOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  ExecutedResultAsc = 'executedResult_ASC',
  ExecutedResultDesc = 'executedResult_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MethodAsc = 'method_ASC',
  MethodDesc = 'method_DESC',
  ModuleAsc = 'module_ASC',
  ModuleDesc = 'module_DESC',
  NoAsc = 'no_ASC',
  NoDesc = 'no_DESC',
  ParametersAsc = 'parameters_ASC',
  ParametersDesc = 'parameters_DESC',
  ProposalHashAsc = 'proposalHash_ASC',
  ProposalHashDesc = 'proposalHash_DESC',
  ProposalIndexAsc = 'proposalIndex_ASC',
  ProposalIndexDesc = 'proposalIndex_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  ThresholdAsc = 'threshold_ASC',
  ThresholdDesc = 'threshold_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  YesAsc = 'yes_ASC',
  YesDesc = 'yes_DESC',
}

export type ProposalHistoryWhereInput = {
  AND?: InputMaybe<Array<ProposalHistoryWhereInput>>;
  OR?: InputMaybe<Array<ProposalHistoryWhereInput>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_containsInsensitive?: InputMaybe<Scalars['String']>;
  account_endsWith?: InputMaybe<Scalars['String']>;
  account_eq?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_isNull?: InputMaybe<Scalars['Boolean']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  account_not_endsWith?: InputMaybe<Scalars['String']>;
  account_not_eq?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_startsWith?: InputMaybe<Scalars['String']>;
  account_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  executedResult_eq?: InputMaybe<ProposalExecutionResult>;
  executedResult_in?: InputMaybe<Array<ProposalExecutionResult>>;
  executedResult_isNull?: InputMaybe<Scalars['Boolean']>;
  executedResult_not_eq?: InputMaybe<ProposalExecutionResult>;
  executedResult_not_in?: InputMaybe<Array<ProposalExecutionResult>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  method_contains?: InputMaybe<Scalars['String']>;
  method_containsInsensitive?: InputMaybe<Scalars['String']>;
  method_endsWith?: InputMaybe<Scalars['String']>;
  method_eq?: InputMaybe<Scalars['String']>;
  method_gt?: InputMaybe<Scalars['String']>;
  method_gte?: InputMaybe<Scalars['String']>;
  method_in?: InputMaybe<Array<Scalars['String']>>;
  method_isNull?: InputMaybe<Scalars['Boolean']>;
  method_lt?: InputMaybe<Scalars['String']>;
  method_lte?: InputMaybe<Scalars['String']>;
  method_not_contains?: InputMaybe<Scalars['String']>;
  method_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  method_not_endsWith?: InputMaybe<Scalars['String']>;
  method_not_eq?: InputMaybe<Scalars['String']>;
  method_not_in?: InputMaybe<Array<Scalars['String']>>;
  method_not_startsWith?: InputMaybe<Scalars['String']>;
  method_startsWith?: InputMaybe<Scalars['String']>;
  module_contains?: InputMaybe<Scalars['String']>;
  module_containsInsensitive?: InputMaybe<Scalars['String']>;
  module_endsWith?: InputMaybe<Scalars['String']>;
  module_eq?: InputMaybe<Scalars['String']>;
  module_gt?: InputMaybe<Scalars['String']>;
  module_gte?: InputMaybe<Scalars['String']>;
  module_in?: InputMaybe<Array<Scalars['String']>>;
  module_isNull?: InputMaybe<Scalars['Boolean']>;
  module_lt?: InputMaybe<Scalars['String']>;
  module_lte?: InputMaybe<Scalars['String']>;
  module_not_contains?: InputMaybe<Scalars['String']>;
  module_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  module_not_endsWith?: InputMaybe<Scalars['String']>;
  module_not_eq?: InputMaybe<Scalars['String']>;
  module_not_in?: InputMaybe<Array<Scalars['String']>>;
  module_not_startsWith?: InputMaybe<Scalars['String']>;
  module_startsWith?: InputMaybe<Scalars['String']>;
  no_eq?: InputMaybe<Scalars['Int']>;
  no_gt?: InputMaybe<Scalars['Int']>;
  no_gte?: InputMaybe<Scalars['Int']>;
  no_in?: InputMaybe<Array<Scalars['Int']>>;
  no_isNull?: InputMaybe<Scalars['Boolean']>;
  no_lt?: InputMaybe<Scalars['Int']>;
  no_lte?: InputMaybe<Scalars['Int']>;
  no_not_eq?: InputMaybe<Scalars['Int']>;
  no_not_in?: InputMaybe<Array<Scalars['Int']>>;
  parameters_contains?: InputMaybe<Scalars['String']>;
  parameters_containsInsensitive?: InputMaybe<Scalars['String']>;
  parameters_endsWith?: InputMaybe<Scalars['String']>;
  parameters_eq?: InputMaybe<Scalars['String']>;
  parameters_gt?: InputMaybe<Scalars['String']>;
  parameters_gte?: InputMaybe<Scalars['String']>;
  parameters_in?: InputMaybe<Array<Scalars['String']>>;
  parameters_isNull?: InputMaybe<Scalars['Boolean']>;
  parameters_lt?: InputMaybe<Scalars['String']>;
  parameters_lte?: InputMaybe<Scalars['String']>;
  parameters_not_contains?: InputMaybe<Scalars['String']>;
  parameters_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  parameters_not_endsWith?: InputMaybe<Scalars['String']>;
  parameters_not_eq?: InputMaybe<Scalars['String']>;
  parameters_not_in?: InputMaybe<Array<Scalars['String']>>;
  parameters_not_startsWith?: InputMaybe<Scalars['String']>;
  parameters_startsWith?: InputMaybe<Scalars['String']>;
  proposalHash_contains?: InputMaybe<Scalars['String']>;
  proposalHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  proposalHash_endsWith?: InputMaybe<Scalars['String']>;
  proposalHash_eq?: InputMaybe<Scalars['String']>;
  proposalHash_gt?: InputMaybe<Scalars['String']>;
  proposalHash_gte?: InputMaybe<Scalars['String']>;
  proposalHash_in?: InputMaybe<Array<Scalars['String']>>;
  proposalHash_isNull?: InputMaybe<Scalars['Boolean']>;
  proposalHash_lt?: InputMaybe<Scalars['String']>;
  proposalHash_lte?: InputMaybe<Scalars['String']>;
  proposalHash_not_contains?: InputMaybe<Scalars['String']>;
  proposalHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  proposalHash_not_endsWith?: InputMaybe<Scalars['String']>;
  proposalHash_not_eq?: InputMaybe<Scalars['String']>;
  proposalHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  proposalHash_not_startsWith?: InputMaybe<Scalars['String']>;
  proposalHash_startsWith?: InputMaybe<Scalars['String']>;
  proposalIndex_eq?: InputMaybe<Scalars['Int']>;
  proposalIndex_gt?: InputMaybe<Scalars['Int']>;
  proposalIndex_gte?: InputMaybe<Scalars['Int']>;
  proposalIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  proposalIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  proposalIndex_lt?: InputMaybe<Scalars['Int']>;
  proposalIndex_lte?: InputMaybe<Scalars['Int']>;
  proposalIndex_not_eq?: InputMaybe<Scalars['Int']>;
  proposalIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  status_eq?: InputMaybe<ProposalStatus>;
  status_in?: InputMaybe<Array<ProposalStatus>>;
  status_isNull?: InputMaybe<Scalars['Boolean']>;
  status_not_eq?: InputMaybe<ProposalStatus>;
  status_not_in?: InputMaybe<Array<ProposalStatus>>;
  threshold_eq?: InputMaybe<Scalars['Int']>;
  threshold_gt?: InputMaybe<Scalars['Int']>;
  threshold_gte?: InputMaybe<Scalars['Int']>;
  threshold_in?: InputMaybe<Array<Scalars['Int']>>;
  threshold_isNull?: InputMaybe<Scalars['Boolean']>;
  threshold_lt?: InputMaybe<Scalars['Int']>;
  threshold_lte?: InputMaybe<Scalars['Int']>;
  threshold_not_eq?: InputMaybe<Scalars['Int']>;
  threshold_not_in?: InputMaybe<Array<Scalars['Int']>>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type_eq?: InputMaybe<ProposalType>;
  type_in?: InputMaybe<Array<ProposalType>>;
  type_isNull?: InputMaybe<Scalars['Boolean']>;
  type_not_eq?: InputMaybe<ProposalType>;
  type_not_in?: InputMaybe<Array<ProposalType>>;
  yes_eq?: InputMaybe<Scalars['Int']>;
  yes_gt?: InputMaybe<Scalars['Int']>;
  yes_gte?: InputMaybe<Scalars['Int']>;
  yes_in?: InputMaybe<Array<Scalars['Int']>>;
  yes_isNull?: InputMaybe<Scalars['Boolean']>;
  yes_lt?: InputMaybe<Scalars['Int']>;
  yes_lte?: InputMaybe<Scalars['Int']>;
  yes_not_eq?: InputMaybe<Scalars['Int']>;
  yes_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type ProposalModel = {
  __typename?: 'ProposalModel';
  account: Scalars['String'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Float'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Float']>;
  history?: Maybe<Array<ProposalHistoryModel>>;
  id: Scalars['String'];
  method: Scalars['String'];
  module: Scalars['String'];
  parameters?: Maybe<Scalars['String']>;
  proposalHash: Scalars['String'];
  proposalIndex?: Maybe<Scalars['Float']>;
  status: ProposalStatus;
  threshold?: Maybe<Scalars['Float']>;
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Float']>;
  type: ProposalType;
};

export enum ProposalOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MethodAsc = 'method_ASC',
  MethodDesc = 'method_DESC',
  ModuleAsc = 'module_ASC',
  ModuleDesc = 'module_DESC',
  ParametersAsc = 'parameters_ASC',
  ParametersDesc = 'parameters_DESC',
  ProposalHashAsc = 'proposalHash_ASC',
  ProposalHashDesc = 'proposalHash_DESC',
  ProposalIndexAsc = 'proposalIndex_ASC',
  ProposalIndexDesc = 'proposalIndex_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  ThresholdAsc = 'threshold_ASC',
  ThresholdDesc = 'threshold_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ProposalResult {
  Failed = 'Failed',
  Passed = 'Passed',
}

export enum ProposalStatus {
  Approved = 'Approved',
  Closed = 'Closed',
  Executed = 'Executed',
  Proposed = 'Proposed',
}

export enum ProposalType {
  Council = 'Council',
  FinancialCommittee = 'FinancialCommittee',
}

export type ProposalWhereInput = {
  AND?: InputMaybe<Array<ProposalWhereInput>>;
  OR?: InputMaybe<Array<ProposalWhereInput>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_containsInsensitive?: InputMaybe<Scalars['String']>;
  account_endsWith?: InputMaybe<Scalars['String']>;
  account_eq?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_isNull?: InputMaybe<Scalars['Boolean']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  account_not_endsWith?: InputMaybe<Scalars['String']>;
  account_not_eq?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_startsWith?: InputMaybe<Scalars['String']>;
  account_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  method_contains?: InputMaybe<Scalars['String']>;
  method_containsInsensitive?: InputMaybe<Scalars['String']>;
  method_endsWith?: InputMaybe<Scalars['String']>;
  method_eq?: InputMaybe<Scalars['String']>;
  method_gt?: InputMaybe<Scalars['String']>;
  method_gte?: InputMaybe<Scalars['String']>;
  method_in?: InputMaybe<Array<Scalars['String']>>;
  method_isNull?: InputMaybe<Scalars['Boolean']>;
  method_lt?: InputMaybe<Scalars['String']>;
  method_lte?: InputMaybe<Scalars['String']>;
  method_not_contains?: InputMaybe<Scalars['String']>;
  method_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  method_not_endsWith?: InputMaybe<Scalars['String']>;
  method_not_eq?: InputMaybe<Scalars['String']>;
  method_not_in?: InputMaybe<Array<Scalars['String']>>;
  method_not_startsWith?: InputMaybe<Scalars['String']>;
  method_startsWith?: InputMaybe<Scalars['String']>;
  module_contains?: InputMaybe<Scalars['String']>;
  module_containsInsensitive?: InputMaybe<Scalars['String']>;
  module_endsWith?: InputMaybe<Scalars['String']>;
  module_eq?: InputMaybe<Scalars['String']>;
  module_gt?: InputMaybe<Scalars['String']>;
  module_gte?: InputMaybe<Scalars['String']>;
  module_in?: InputMaybe<Array<Scalars['String']>>;
  module_isNull?: InputMaybe<Scalars['Boolean']>;
  module_lt?: InputMaybe<Scalars['String']>;
  module_lte?: InputMaybe<Scalars['String']>;
  module_not_contains?: InputMaybe<Scalars['String']>;
  module_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  module_not_endsWith?: InputMaybe<Scalars['String']>;
  module_not_eq?: InputMaybe<Scalars['String']>;
  module_not_in?: InputMaybe<Array<Scalars['String']>>;
  module_not_startsWith?: InputMaybe<Scalars['String']>;
  module_startsWith?: InputMaybe<Scalars['String']>;
  parameters_contains?: InputMaybe<Scalars['String']>;
  parameters_containsInsensitive?: InputMaybe<Scalars['String']>;
  parameters_endsWith?: InputMaybe<Scalars['String']>;
  parameters_eq?: InputMaybe<Scalars['String']>;
  parameters_gt?: InputMaybe<Scalars['String']>;
  parameters_gte?: InputMaybe<Scalars['String']>;
  parameters_in?: InputMaybe<Array<Scalars['String']>>;
  parameters_isNull?: InputMaybe<Scalars['Boolean']>;
  parameters_lt?: InputMaybe<Scalars['String']>;
  parameters_lte?: InputMaybe<Scalars['String']>;
  parameters_not_contains?: InputMaybe<Scalars['String']>;
  parameters_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  parameters_not_endsWith?: InputMaybe<Scalars['String']>;
  parameters_not_eq?: InputMaybe<Scalars['String']>;
  parameters_not_in?: InputMaybe<Array<Scalars['String']>>;
  parameters_not_startsWith?: InputMaybe<Scalars['String']>;
  parameters_startsWith?: InputMaybe<Scalars['String']>;
  proposalHash_contains?: InputMaybe<Scalars['String']>;
  proposalHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  proposalHash_endsWith?: InputMaybe<Scalars['String']>;
  proposalHash_eq?: InputMaybe<Scalars['String']>;
  proposalHash_gt?: InputMaybe<Scalars['String']>;
  proposalHash_gte?: InputMaybe<Scalars['String']>;
  proposalHash_in?: InputMaybe<Array<Scalars['String']>>;
  proposalHash_isNull?: InputMaybe<Scalars['Boolean']>;
  proposalHash_lt?: InputMaybe<Scalars['String']>;
  proposalHash_lte?: InputMaybe<Scalars['String']>;
  proposalHash_not_contains?: InputMaybe<Scalars['String']>;
  proposalHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  proposalHash_not_endsWith?: InputMaybe<Scalars['String']>;
  proposalHash_not_eq?: InputMaybe<Scalars['String']>;
  proposalHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  proposalHash_not_startsWith?: InputMaybe<Scalars['String']>;
  proposalHash_startsWith?: InputMaybe<Scalars['String']>;
  proposalIndex_eq?: InputMaybe<Scalars['Int']>;
  proposalIndex_gt?: InputMaybe<Scalars['Int']>;
  proposalIndex_gte?: InputMaybe<Scalars['Int']>;
  proposalIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  proposalIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  proposalIndex_lt?: InputMaybe<Scalars['Int']>;
  proposalIndex_lte?: InputMaybe<Scalars['Int']>;
  proposalIndex_not_eq?: InputMaybe<Scalars['Int']>;
  proposalIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  status_eq?: InputMaybe<ProposalStatus>;
  status_in?: InputMaybe<Array<ProposalStatus>>;
  status_isNull?: InputMaybe<Scalars['Boolean']>;
  status_not_eq?: InputMaybe<ProposalStatus>;
  status_not_in?: InputMaybe<Array<ProposalStatus>>;
  threshold_eq?: InputMaybe<Scalars['Int']>;
  threshold_gt?: InputMaybe<Scalars['Int']>;
  threshold_gte?: InputMaybe<Scalars['Int']>;
  threshold_in?: InputMaybe<Array<Scalars['Int']>>;
  threshold_isNull?: InputMaybe<Scalars['Boolean']>;
  threshold_lt?: InputMaybe<Scalars['Int']>;
  threshold_lte?: InputMaybe<Scalars['Int']>;
  threshold_not_eq?: InputMaybe<Scalars['Int']>;
  threshold_not_in?: InputMaybe<Array<Scalars['Int']>>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  type_eq?: InputMaybe<ProposalType>;
  type_in?: InputMaybe<Array<ProposalType>>;
  type_isNull?: InputMaybe<Scalars['Boolean']>;
  type_not_eq?: InputMaybe<ProposalType>;
  type_not_in?: InputMaybe<Array<ProposalType>>;
};

export type ProposalsConnection = {
  __typename?: 'ProposalsConnection';
  edges: Array<ProposalEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  allProposals: ProposalConnectionModel;
  assetById?: Maybe<Asset>;
  /** @deprecated Use assetById */
  assetByUniqueInput?: Maybe<Asset>;
  assetTransferHistory: AssetTransferHistoryConnectionModel;
  assets: Array<Asset>;
  assetsConnection: AssetsConnection;
  catalystTransferHistories: Array<CatalystTransferHistory>;
  catalystTransferHistoriesConnection: CatalystTransferHistoriesConnection;
  catalystTransferHistory: CatalystTransactionConnectionModel;
  catalystTransferHistoryById?: Maybe<CatalystTransferHistory>;
  /** @deprecated Use catalystTransferHistoryById */
  catalystTransferHistoryByUniqueInput?: Maybe<CatalystTransferHistory>;
  dexHistories: Array<DexHistory>;
  dexHistoriesConnection: DexHistoriesConnection;
  dexHistory: DexHistoryConnectionModel;
  dexHistoryById?: Maybe<DexHistory>;
  /** @deprecated Use dexHistoryById */
  dexHistoryByUniqueInput?: Maybe<DexHistory>;
  plugCataDepositById?: Maybe<PlugCataDeposit>;
  /** @deprecated Use plugCataDepositById */
  plugCataDepositByUniqueInput?: Maybe<PlugCataDeposit>;
  plugCataDepositHistory: PlugCatalystDepositConnectionModel;
  plugCataDeposits: Array<PlugCataDeposit>;
  plugCataDepositsConnection: PlugCataDepositsConnection;
  plugCataPaidOutById?: Maybe<PlugCataPaidOut>;
  /** @deprecated Use plugCataPaidOutById */
  plugCataPaidOutByUniqueInput?: Maybe<PlugCataPaidOut>;
  plugCataPaidOutHistory: CatalystVoucherAirdropModel;
  plugCataPaidOuts: Array<PlugCataPaidOut>;
  plugCataPaidOutsConnection: PlugCataPaidOutsConnection;
  plugCataRedeemById?: Maybe<PlugCataRedeem>;
  /** @deprecated Use plugCataRedeemById */
  plugCataRedeemByUniqueInput?: Maybe<PlugCataRedeem>;
  plugCataRedeemHistory: CatalystVoucherRedeemedTransactionConnectionModel;
  plugCataRedeems: Array<PlugCataRedeem>;
  plugCataRedeemsConnection: PlugCataRedeemsConnection;
  proposalById?: Maybe<Proposal>;
  /** @deprecated Use proposalById */
  proposalByUniqueInput?: Maybe<Proposal>;
  proposalHistories: Array<ProposalHistory>;
  proposalHistoriesConnection: ProposalHistoriesConnection;
  proposalHistoryById?: Maybe<ProposalHistory>;
  /** @deprecated Use proposalHistoryById */
  proposalHistoryByUniqueInput?: Maybe<ProposalHistory>;
  proposals: Array<Proposal>;
  proposalsConnection: ProposalsConnection;
  squidStatus?: Maybe<SquidStatus>;
  tradingPoolById?: Maybe<TradingPool>;
  /** @deprecated Use tradingPoolById */
  tradingPoolByUniqueInput?: Maybe<TradingPool>;
  tradingPoolDepthById?: Maybe<TradingPoolDepth>;
  /** @deprecated Use tradingPoolDepthById */
  tradingPoolDepthByUniqueInput?: Maybe<TradingPoolDepth>;
  tradingPoolDepths: Array<TradingPoolDepth>;
  tradingPoolDepthsConnection: TradingPoolDepthsConnection;
  tradingPools: Array<TradingPool>;
  tradingPoolsConnection: TradingPoolsConnection;
  transferById?: Maybe<Transfer>;
  /** @deprecated Use transferById */
  transferByUniqueInput?: Maybe<Transfer>;
  transferHistories: Array<TransferHistory>;
  transferHistoriesConnection: TransferHistoriesConnection;
  transferHistoryById?: Maybe<TransferHistory>;
  /** @deprecated Use transferHistoryById */
  transferHistoryByUniqueInput?: Maybe<TransferHistory>;
  transfers: Array<Transfer>;
  transfersConnection: TransfersConnection;
  voteById?: Maybe<Vote>;
  /** @deprecated Use voteById */
  voteByUniqueInput?: Maybe<Vote>;
  votes: Array<Vote>;
  votesConnection: VotesConnection;
};

export type QueryAllProposalsArgs = {
  councilType?: InputMaybe<ProposalCouncilType>;
  page?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  proposalHash?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ProposalResult>;
  status?: InputMaybe<ProposalStatus>;
};

export type QueryAssetByIdArgs = {
  id: Scalars['String'];
};

export type QueryAssetByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryAssetTransferHistoryArgs = {
  address: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderByInput>>;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<AssetOrderByInput>;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryCatalystTransferHistoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalystTransferHistoryOrderByInput>>;
  where?: InputMaybe<CatalystTransferHistoryWhereInput>;
};

export type QueryCatalystTransferHistoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<CatalystTransferHistoryOrderByInput>;
  where?: InputMaybe<CatalystTransferHistoryWhereInput>;
};

export type QueryCatalystTransferHistoryArgs = {
  methodsIn?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type QueryCatalystTransferHistoryByIdArgs = {
  id: Scalars['String'];
};

export type QueryCatalystTransferHistoryByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryDexHistoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DexHistoryOrderByInput>>;
  where?: InputMaybe<DexHistoryWhereInput>;
};

export type QueryDexHistoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<DexHistoryOrderByInput>;
  where?: InputMaybe<DexHistoryWhereInput>;
};

export type QueryDexHistoryArgs = {
  owner?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  poolAssetId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DexHistoryType>;
};

export type QueryDexHistoryByIdArgs = {
  id: Scalars['String'];
};

export type QueryDexHistoryByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryPlugCataDepositByIdArgs = {
  id: Scalars['String'];
};

export type QueryPlugCataDepositByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryPlugCataDepositHistoryArgs = {
  address?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryPlugCataDepositsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlugCataDepositOrderByInput>>;
  where?: InputMaybe<PlugCataDepositWhereInput>;
};

export type QueryPlugCataDepositsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<PlugCataDepositOrderByInput>;
  where?: InputMaybe<PlugCataDepositWhereInput>;
};

export type QueryPlugCataPaidOutByIdArgs = {
  id: Scalars['String'];
};

export type QueryPlugCataPaidOutByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryPlugCataPaidOutHistoryArgs = {
  address: Scalars['String'];
};

export type QueryPlugCataPaidOutsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlugCataPaidOutOrderByInput>>;
  where?: InputMaybe<PlugCataPaidOutWhereInput>;
};

export type QueryPlugCataPaidOutsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<PlugCataPaidOutOrderByInput>;
  where?: InputMaybe<PlugCataPaidOutWhereInput>;
};

export type QueryPlugCataRedeemByIdArgs = {
  id: Scalars['String'];
};

export type QueryPlugCataRedeemByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryPlugCataRedeemHistoryArgs = {
  address?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryPlugCataRedeemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlugCataRedeemOrderByInput>>;
  where?: InputMaybe<PlugCataRedeemWhereInput>;
};

export type QueryPlugCataRedeemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<PlugCataRedeemOrderByInput>;
  where?: InputMaybe<PlugCataRedeemWhereInput>;
};

export type QueryProposalByIdArgs = {
  id: Scalars['String'];
};

export type QueryProposalByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryProposalHistoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProposalHistoryOrderByInput>>;
  where?: InputMaybe<ProposalHistoryWhereInput>;
};

export type QueryProposalHistoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<ProposalHistoryOrderByInput>;
  where?: InputMaybe<ProposalHistoryWhereInput>;
};

export type QueryProposalHistoryByIdArgs = {
  id: Scalars['String'];
};

export type QueryProposalHistoryByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryProposalsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProposalOrderByInput>>;
  where?: InputMaybe<ProposalWhereInput>;
};

export type QueryProposalsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<ProposalOrderByInput>;
  where?: InputMaybe<ProposalWhereInput>;
};

export type QueryTradingPoolByIdArgs = {
  id: Scalars['String'];
};

export type QueryTradingPoolByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryTradingPoolDepthByIdArgs = {
  id: Scalars['String'];
};

export type QueryTradingPoolDepthByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryTradingPoolDepthsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradingPoolDepthOrderByInput>>;
  where?: InputMaybe<TradingPoolDepthWhereInput>;
};

export type QueryTradingPoolDepthsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<TradingPoolDepthOrderByInput>;
  where?: InputMaybe<TradingPoolDepthWhereInput>;
};

export type QueryTradingPoolsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradingPoolOrderByInput>>;
  where?: InputMaybe<TradingPoolWhereInput>;
};

export type QueryTradingPoolsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<TradingPoolOrderByInput>;
  where?: InputMaybe<TradingPoolWhereInput>;
};

export type QueryTransferByIdArgs = {
  id: Scalars['String'];
};

export type QueryTransferByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryTransferHistoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransferHistoryOrderByInput>>;
  where?: InputMaybe<TransferHistoryWhereInput>;
};

export type QueryTransferHistoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<TransferHistoryOrderByInput>;
  where?: InputMaybe<TransferHistoryWhereInput>;
};

export type QueryTransferHistoryByIdArgs = {
  id: Scalars['String'];
};

export type QueryTransferHistoryByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryTransfersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransferOrderByInput>>;
  where?: InputMaybe<TransferWhereInput>;
};

export type QueryTransfersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<TransferOrderByInput>;
  where?: InputMaybe<TransferWhereInput>;
};

export type QueryVoteByIdArgs = {
  id: Scalars['String'];
};

export type QueryVoteByUniqueInputArgs = {
  where: WhereIdInput;
};

export type QueryVotesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VoteOrderByInput>>;
  where?: InputMaybe<VoteWhereInput>;
};

export type QueryVotesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy: Array<VoteOrderByInput>;
  where?: InputMaybe<VoteWhereInput>;
};

export type SquidStatus = {
  __typename?: 'SquidStatus';
  /** The height of the processed part of the chain */
  height?: Maybe<Scalars['Int']>;
};

export type TradingPool = {
  __typename?: 'TradingPool';
  asset0: Scalars['BigInt'];
  asset1: Scalars['BigInt'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  disabledAt?: Maybe<Scalars['String']>;
  enabledAt?: Maybe<Scalars['String']>;
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  initShares?: Maybe<Scalars['BigInt']>;
  listedAt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
};

export type TradingPoolDepth = {
  __typename?: 'TradingPoolDepth';
  assetId: Scalars['BigInt'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  cumulativeLast: Scalars['BigInt'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  tradingPoolId: Scalars['BigInt'];
  txIndex?: Maybe<Scalars['Int']>;
};

export type TradingPoolDepthEdge = {
  __typename?: 'TradingPoolDepthEdge';
  cursor: Scalars['String'];
  node: TradingPoolDepth;
};

export enum TradingPoolDepthOrderByInput {
  AssetIdAsc = 'assetId_ASC',
  AssetIdDesc = 'assetId_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  CumulativeLastAsc = 'cumulativeLast_ASC',
  CumulativeLastDesc = 'cumulativeLast_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TradingPoolIdAsc = 'tradingPoolId_ASC',
  TradingPoolIdDesc = 'tradingPoolId_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
}

export type TradingPoolDepthWhereInput = {
  AND?: InputMaybe<Array<TradingPoolDepthWhereInput>>;
  OR?: InputMaybe<Array<TradingPoolDepthWhereInput>>;
  assetId_eq?: InputMaybe<Scalars['BigInt']>;
  assetId_gt?: InputMaybe<Scalars['BigInt']>;
  assetId_gte?: InputMaybe<Scalars['BigInt']>;
  assetId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assetId_isNull?: InputMaybe<Scalars['Boolean']>;
  assetId_lt?: InputMaybe<Scalars['BigInt']>;
  assetId_lte?: InputMaybe<Scalars['BigInt']>;
  assetId_not_eq?: InputMaybe<Scalars['BigInt']>;
  assetId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  cumulativeLast_eq?: InputMaybe<Scalars['BigInt']>;
  cumulativeLast_gt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLast_gte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLast_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cumulativeLast_isNull?: InputMaybe<Scalars['Boolean']>;
  cumulativeLast_lt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLast_lte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLast_not_eq?: InputMaybe<Scalars['BigInt']>;
  cumulativeLast_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  tradingPoolId_eq?: InputMaybe<Scalars['BigInt']>;
  tradingPoolId_gt?: InputMaybe<Scalars['BigInt']>;
  tradingPoolId_gte?: InputMaybe<Scalars['BigInt']>;
  tradingPoolId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tradingPoolId_isNull?: InputMaybe<Scalars['Boolean']>;
  tradingPoolId_lt?: InputMaybe<Scalars['BigInt']>;
  tradingPoolId_lte?: InputMaybe<Scalars['BigInt']>;
  tradingPoolId_not_eq?: InputMaybe<Scalars['BigInt']>;
  tradingPoolId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type TradingPoolDepthsConnection = {
  __typename?: 'TradingPoolDepthsConnection';
  edges: Array<TradingPoolDepthEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TradingPoolEdge = {
  __typename?: 'TradingPoolEdge';
  cursor: Scalars['String'];
  node: TradingPool;
};

export enum TradingPoolOrderByInput {
  Asset0Asc = 'asset0_ASC',
  Asset0Desc = 'asset0_DESC',
  Asset1Asc = 'asset1_ASC',
  Asset1Desc = 'asset1_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  DisabledAtAsc = 'disabledAt_ASC',
  DisabledAtDesc = 'disabledAt_DESC',
  EnabledAtAsc = 'enabledAt_ASC',
  EnabledAtDesc = 'enabledAt_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InitSharesAsc = 'initShares_ASC',
  InitSharesDesc = 'initShares_DESC',
  ListedAtAsc = 'listedAt_ASC',
  ListedAtDesc = 'listedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
}

export type TradingPoolWhereInput = {
  AND?: InputMaybe<Array<TradingPoolWhereInput>>;
  OR?: InputMaybe<Array<TradingPoolWhereInput>>;
  asset0_eq?: InputMaybe<Scalars['BigInt']>;
  asset0_gt?: InputMaybe<Scalars['BigInt']>;
  asset0_gte?: InputMaybe<Scalars['BigInt']>;
  asset0_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset0_isNull?: InputMaybe<Scalars['Boolean']>;
  asset0_lt?: InputMaybe<Scalars['BigInt']>;
  asset0_lte?: InputMaybe<Scalars['BigInt']>;
  asset0_not_eq?: InputMaybe<Scalars['BigInt']>;
  asset0_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset1_eq?: InputMaybe<Scalars['BigInt']>;
  asset1_gt?: InputMaybe<Scalars['BigInt']>;
  asset1_gte?: InputMaybe<Scalars['BigInt']>;
  asset1_in?: InputMaybe<Array<Scalars['BigInt']>>;
  asset1_isNull?: InputMaybe<Scalars['Boolean']>;
  asset1_lt?: InputMaybe<Scalars['BigInt']>;
  asset1_lte?: InputMaybe<Scalars['BigInt']>;
  asset1_not_eq?: InputMaybe<Scalars['BigInt']>;
  asset1_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  disabledAt_contains?: InputMaybe<Scalars['String']>;
  disabledAt_containsInsensitive?: InputMaybe<Scalars['String']>;
  disabledAt_endsWith?: InputMaybe<Scalars['String']>;
  disabledAt_eq?: InputMaybe<Scalars['String']>;
  disabledAt_gt?: InputMaybe<Scalars['String']>;
  disabledAt_gte?: InputMaybe<Scalars['String']>;
  disabledAt_in?: InputMaybe<Array<Scalars['String']>>;
  disabledAt_isNull?: InputMaybe<Scalars['Boolean']>;
  disabledAt_lt?: InputMaybe<Scalars['String']>;
  disabledAt_lte?: InputMaybe<Scalars['String']>;
  disabledAt_not_contains?: InputMaybe<Scalars['String']>;
  disabledAt_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  disabledAt_not_endsWith?: InputMaybe<Scalars['String']>;
  disabledAt_not_eq?: InputMaybe<Scalars['String']>;
  disabledAt_not_in?: InputMaybe<Array<Scalars['String']>>;
  disabledAt_not_startsWith?: InputMaybe<Scalars['String']>;
  disabledAt_startsWith?: InputMaybe<Scalars['String']>;
  enabledAt_contains?: InputMaybe<Scalars['String']>;
  enabledAt_containsInsensitive?: InputMaybe<Scalars['String']>;
  enabledAt_endsWith?: InputMaybe<Scalars['String']>;
  enabledAt_eq?: InputMaybe<Scalars['String']>;
  enabledAt_gt?: InputMaybe<Scalars['String']>;
  enabledAt_gte?: InputMaybe<Scalars['String']>;
  enabledAt_in?: InputMaybe<Array<Scalars['String']>>;
  enabledAt_isNull?: InputMaybe<Scalars['Boolean']>;
  enabledAt_lt?: InputMaybe<Scalars['String']>;
  enabledAt_lte?: InputMaybe<Scalars['String']>;
  enabledAt_not_contains?: InputMaybe<Scalars['String']>;
  enabledAt_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  enabledAt_not_endsWith?: InputMaybe<Scalars['String']>;
  enabledAt_not_eq?: InputMaybe<Scalars['String']>;
  enabledAt_not_in?: InputMaybe<Array<Scalars['String']>>;
  enabledAt_not_startsWith?: InputMaybe<Scalars['String']>;
  enabledAt_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  initShares_eq?: InputMaybe<Scalars['BigInt']>;
  initShares_gt?: InputMaybe<Scalars['BigInt']>;
  initShares_gte?: InputMaybe<Scalars['BigInt']>;
  initShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initShares_isNull?: InputMaybe<Scalars['Boolean']>;
  initShares_lt?: InputMaybe<Scalars['BigInt']>;
  initShares_lte?: InputMaybe<Scalars['BigInt']>;
  initShares_not_eq?: InputMaybe<Scalars['BigInt']>;
  initShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  listedAt_contains?: InputMaybe<Scalars['String']>;
  listedAt_containsInsensitive?: InputMaybe<Scalars['String']>;
  listedAt_endsWith?: InputMaybe<Scalars['String']>;
  listedAt_eq?: InputMaybe<Scalars['String']>;
  listedAt_gt?: InputMaybe<Scalars['String']>;
  listedAt_gte?: InputMaybe<Scalars['String']>;
  listedAt_in?: InputMaybe<Array<Scalars['String']>>;
  listedAt_isNull?: InputMaybe<Scalars['Boolean']>;
  listedAt_lt?: InputMaybe<Scalars['String']>;
  listedAt_lte?: InputMaybe<Scalars['String']>;
  listedAt_not_contains?: InputMaybe<Scalars['String']>;
  listedAt_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  listedAt_not_endsWith?: InputMaybe<Scalars['String']>;
  listedAt_not_eq?: InputMaybe<Scalars['String']>;
  listedAt_not_in?: InputMaybe<Array<Scalars['String']>>;
  listedAt_not_startsWith?: InputMaybe<Scalars['String']>;
  listedAt_startsWith?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_containsInsensitive?: InputMaybe<Scalars['String']>;
  name_endsWith?: InputMaybe<Scalars['String']>;
  name_eq?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_isNull?: InputMaybe<Scalars['Boolean']>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  name_not_endsWith?: InputMaybe<Scalars['String']>;
  name_not_eq?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_startsWith?: InputMaybe<Scalars['String']>;
  name_startsWith?: InputMaybe<Scalars['String']>;
  status_contains?: InputMaybe<Scalars['String']>;
  status_containsInsensitive?: InputMaybe<Scalars['String']>;
  status_endsWith?: InputMaybe<Scalars['String']>;
  status_eq?: InputMaybe<Scalars['String']>;
  status_gt?: InputMaybe<Scalars['String']>;
  status_gte?: InputMaybe<Scalars['String']>;
  status_in?: InputMaybe<Array<Scalars['String']>>;
  status_isNull?: InputMaybe<Scalars['Boolean']>;
  status_lt?: InputMaybe<Scalars['String']>;
  status_lte?: InputMaybe<Scalars['String']>;
  status_not_contains?: InputMaybe<Scalars['String']>;
  status_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  status_not_endsWith?: InputMaybe<Scalars['String']>;
  status_not_eq?: InputMaybe<Scalars['String']>;
  status_not_in?: InputMaybe<Array<Scalars['String']>>;
  status_not_startsWith?: InputMaybe<Scalars['String']>;
  status_startsWith?: InputMaybe<Scalars['String']>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type TradingPoolsConnection = {
  __typename?: 'TradingPoolsConnection';
  edges: Array<TradingPoolEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Transfer = {
  __typename?: 'Transfer';
  amount: Scalars['BigInt'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  fee: Scalars['BigInt'];
  from: Scalars['String'];
  id: Scalars['String'];
  success: Scalars['Boolean'];
  timestamp: Scalars['DateTime'];
  to: Scalars['String'];
  txIndex?: Maybe<Scalars['Int']>;
};

export type TransferEdge = {
  __typename?: 'TransferEdge';
  cursor: Scalars['String'];
  node: Transfer;
};

export type TransferHistoriesConnection = {
  __typename?: 'TransferHistoriesConnection';
  edges: Array<TransferHistoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TransferHistory = {
  __typename?: 'TransferHistory';
  amount?: Maybe<Scalars['BigInt']>;
  assetId?: Maybe<Scalars['BigInt']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  timestamp: Scalars['DateTime'];
  to?: Maybe<Scalars['String']>;
  txIndex?: Maybe<Scalars['Int']>;
};

export type TransferHistoryEdge = {
  __typename?: 'TransferHistoryEdge';
  cursor: Scalars['String'];
  node: TransferHistory;
};

export enum TransferHistoryOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  AssetIdAsc = 'assetId_ASC',
  AssetIdDesc = 'assetId_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  FromAsc = 'from_ASC',
  FromDesc = 'from_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MethodAsc = 'method_ASC',
  MethodDesc = 'method_DESC',
  ModuleAsc = 'module_ASC',
  ModuleDesc = 'module_DESC',
  SuccessAsc = 'success_ASC',
  SuccessDesc = 'success_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  ToAsc = 'to_ASC',
  ToDesc = 'to_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
}

export type TransferHistoryWhereInput = {
  AND?: InputMaybe<Array<TransferHistoryWhereInput>>;
  OR?: InputMaybe<Array<TransferHistoryWhereInput>>;
  amount_eq?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_isNull?: InputMaybe<Scalars['Boolean']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_not_eq?: InputMaybe<Scalars['BigInt']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assetId_eq?: InputMaybe<Scalars['BigInt']>;
  assetId_gt?: InputMaybe<Scalars['BigInt']>;
  assetId_gte?: InputMaybe<Scalars['BigInt']>;
  assetId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assetId_isNull?: InputMaybe<Scalars['Boolean']>;
  assetId_lt?: InputMaybe<Scalars['BigInt']>;
  assetId_lte?: InputMaybe<Scalars['BigInt']>;
  assetId_not_eq?: InputMaybe<Scalars['BigInt']>;
  assetId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  from_contains?: InputMaybe<Scalars['String']>;
  from_containsInsensitive?: InputMaybe<Scalars['String']>;
  from_endsWith?: InputMaybe<Scalars['String']>;
  from_eq?: InputMaybe<Scalars['String']>;
  from_gt?: InputMaybe<Scalars['String']>;
  from_gte?: InputMaybe<Scalars['String']>;
  from_in?: InputMaybe<Array<Scalars['String']>>;
  from_isNull?: InputMaybe<Scalars['Boolean']>;
  from_lt?: InputMaybe<Scalars['String']>;
  from_lte?: InputMaybe<Scalars['String']>;
  from_not_contains?: InputMaybe<Scalars['String']>;
  from_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  from_not_endsWith?: InputMaybe<Scalars['String']>;
  from_not_eq?: InputMaybe<Scalars['String']>;
  from_not_in?: InputMaybe<Array<Scalars['String']>>;
  from_not_startsWith?: InputMaybe<Scalars['String']>;
  from_startsWith?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  method_contains?: InputMaybe<Scalars['String']>;
  method_containsInsensitive?: InputMaybe<Scalars['String']>;
  method_endsWith?: InputMaybe<Scalars['String']>;
  method_eq?: InputMaybe<Scalars['String']>;
  method_gt?: InputMaybe<Scalars['String']>;
  method_gte?: InputMaybe<Scalars['String']>;
  method_in?: InputMaybe<Array<Scalars['String']>>;
  method_isNull?: InputMaybe<Scalars['Boolean']>;
  method_lt?: InputMaybe<Scalars['String']>;
  method_lte?: InputMaybe<Scalars['String']>;
  method_not_contains?: InputMaybe<Scalars['String']>;
  method_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  method_not_endsWith?: InputMaybe<Scalars['String']>;
  method_not_eq?: InputMaybe<Scalars['String']>;
  method_not_in?: InputMaybe<Array<Scalars['String']>>;
  method_not_startsWith?: InputMaybe<Scalars['String']>;
  method_startsWith?: InputMaybe<Scalars['String']>;
  module_contains?: InputMaybe<Scalars['String']>;
  module_containsInsensitive?: InputMaybe<Scalars['String']>;
  module_endsWith?: InputMaybe<Scalars['String']>;
  module_eq?: InputMaybe<Scalars['String']>;
  module_gt?: InputMaybe<Scalars['String']>;
  module_gte?: InputMaybe<Scalars['String']>;
  module_in?: InputMaybe<Array<Scalars['String']>>;
  module_isNull?: InputMaybe<Scalars['Boolean']>;
  module_lt?: InputMaybe<Scalars['String']>;
  module_lte?: InputMaybe<Scalars['String']>;
  module_not_contains?: InputMaybe<Scalars['String']>;
  module_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  module_not_endsWith?: InputMaybe<Scalars['String']>;
  module_not_eq?: InputMaybe<Scalars['String']>;
  module_not_in?: InputMaybe<Array<Scalars['String']>>;
  module_not_startsWith?: InputMaybe<Scalars['String']>;
  module_startsWith?: InputMaybe<Scalars['String']>;
  success_eq?: InputMaybe<Scalars['Boolean']>;
  success_isNull?: InputMaybe<Scalars['Boolean']>;
  success_not_eq?: InputMaybe<Scalars['Boolean']>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  to_contains?: InputMaybe<Scalars['String']>;
  to_containsInsensitive?: InputMaybe<Scalars['String']>;
  to_endsWith?: InputMaybe<Scalars['String']>;
  to_eq?: InputMaybe<Scalars['String']>;
  to_gt?: InputMaybe<Scalars['String']>;
  to_gte?: InputMaybe<Scalars['String']>;
  to_in?: InputMaybe<Array<Scalars['String']>>;
  to_isNull?: InputMaybe<Scalars['Boolean']>;
  to_lt?: InputMaybe<Scalars['String']>;
  to_lte?: InputMaybe<Scalars['String']>;
  to_not_contains?: InputMaybe<Scalars['String']>;
  to_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  to_not_endsWith?: InputMaybe<Scalars['String']>;
  to_not_eq?: InputMaybe<Scalars['String']>;
  to_not_in?: InputMaybe<Array<Scalars['String']>>;
  to_not_startsWith?: InputMaybe<Scalars['String']>;
  to_startsWith?: InputMaybe<Scalars['String']>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export enum TransferOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  FromAsc = 'from_ASC',
  FromDesc = 'from_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SuccessAsc = 'success_ASC',
  SuccessDesc = 'success_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  ToAsc = 'to_ASC',
  ToDesc = 'to_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
}

export type TransferWhereInput = {
  AND?: InputMaybe<Array<TransferWhereInput>>;
  OR?: InputMaybe<Array<TransferWhereInput>>;
  amount_eq?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_isNull?: InputMaybe<Scalars['Boolean']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_not_eq?: InputMaybe<Scalars['BigInt']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  fee_eq?: InputMaybe<Scalars['BigInt']>;
  fee_gt?: InputMaybe<Scalars['BigInt']>;
  fee_gte?: InputMaybe<Scalars['BigInt']>;
  fee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fee_isNull?: InputMaybe<Scalars['Boolean']>;
  fee_lt?: InputMaybe<Scalars['BigInt']>;
  fee_lte?: InputMaybe<Scalars['BigInt']>;
  fee_not_eq?: InputMaybe<Scalars['BigInt']>;
  fee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  from_contains?: InputMaybe<Scalars['String']>;
  from_containsInsensitive?: InputMaybe<Scalars['String']>;
  from_endsWith?: InputMaybe<Scalars['String']>;
  from_eq?: InputMaybe<Scalars['String']>;
  from_gt?: InputMaybe<Scalars['String']>;
  from_gte?: InputMaybe<Scalars['String']>;
  from_in?: InputMaybe<Array<Scalars['String']>>;
  from_isNull?: InputMaybe<Scalars['Boolean']>;
  from_lt?: InputMaybe<Scalars['String']>;
  from_lte?: InputMaybe<Scalars['String']>;
  from_not_contains?: InputMaybe<Scalars['String']>;
  from_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  from_not_endsWith?: InputMaybe<Scalars['String']>;
  from_not_eq?: InputMaybe<Scalars['String']>;
  from_not_in?: InputMaybe<Array<Scalars['String']>>;
  from_not_startsWith?: InputMaybe<Scalars['String']>;
  from_startsWith?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  success_eq?: InputMaybe<Scalars['Boolean']>;
  success_isNull?: InputMaybe<Scalars['Boolean']>;
  success_not_eq?: InputMaybe<Scalars['Boolean']>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  to_contains?: InputMaybe<Scalars['String']>;
  to_containsInsensitive?: InputMaybe<Scalars['String']>;
  to_endsWith?: InputMaybe<Scalars['String']>;
  to_eq?: InputMaybe<Scalars['String']>;
  to_gt?: InputMaybe<Scalars['String']>;
  to_gte?: InputMaybe<Scalars['String']>;
  to_in?: InputMaybe<Array<Scalars['String']>>;
  to_isNull?: InputMaybe<Scalars['Boolean']>;
  to_lt?: InputMaybe<Scalars['String']>;
  to_lte?: InputMaybe<Scalars['String']>;
  to_not_contains?: InputMaybe<Scalars['String']>;
  to_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  to_not_endsWith?: InputMaybe<Scalars['String']>;
  to_not_eq?: InputMaybe<Scalars['String']>;
  to_not_in?: InputMaybe<Array<Scalars['String']>>;
  to_not_startsWith?: InputMaybe<Scalars['String']>;
  to_startsWith?: InputMaybe<Scalars['String']>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type TransfersConnection = {
  __typename?: 'TransfersConnection';
  edges: Array<TransferEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Vote = {
  __typename?: 'Vote';
  account: Scalars['String'];
  blockHash?: Maybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  extrinsicHash?: Maybe<Scalars['String']>;
  extrinsicId?: Maybe<Scalars['String']>;
  extrinsicIndex?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  no?: Maybe<Scalars['Int']>;
  proposalHash: Scalars['String'];
  timestamp: Scalars['DateTime'];
  txIndex?: Maybe<Scalars['Int']>;
  voted?: Maybe<Scalars['Boolean']>;
  yes?: Maybe<Scalars['Int']>;
};

export type VoteEdge = {
  __typename?: 'VoteEdge';
  cursor: Scalars['String'];
  node: Vote;
};

export enum VoteOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  BlockHashAsc = 'blockHash_ASC',
  BlockHashDesc = 'blockHash_DESC',
  BlockNumberAsc = 'blockNumber_ASC',
  BlockNumberDesc = 'blockNumber_DESC',
  ExtrinsicHashAsc = 'extrinsicHash_ASC',
  ExtrinsicHashDesc = 'extrinsicHash_DESC',
  ExtrinsicIdAsc = 'extrinsicId_ASC',
  ExtrinsicIdDesc = 'extrinsicId_DESC',
  ExtrinsicIndexAsc = 'extrinsicIndex_ASC',
  ExtrinsicIndexDesc = 'extrinsicIndex_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NoAsc = 'no_ASC',
  NoDesc = 'no_DESC',
  ProposalHashAsc = 'proposalHash_ASC',
  ProposalHashDesc = 'proposalHash_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  TxIndexAsc = 'txIndex_ASC',
  TxIndexDesc = 'txIndex_DESC',
  VotedAsc = 'voted_ASC',
  VotedDesc = 'voted_DESC',
  YesAsc = 'yes_ASC',
  YesDesc = 'yes_DESC',
}

export type VoteWhereInput = {
  AND?: InputMaybe<Array<VoteWhereInput>>;
  OR?: InputMaybe<Array<VoteWhereInput>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_containsInsensitive?: InputMaybe<Scalars['String']>;
  account_endsWith?: InputMaybe<Scalars['String']>;
  account_eq?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_isNull?: InputMaybe<Scalars['Boolean']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  account_not_endsWith?: InputMaybe<Scalars['String']>;
  account_not_eq?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_startsWith?: InputMaybe<Scalars['String']>;
  account_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_contains?: InputMaybe<Scalars['String']>;
  blockHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_eq?: InputMaybe<Scalars['String']>;
  blockHash_gt?: InputMaybe<Scalars['String']>;
  blockHash_gte?: InputMaybe<Scalars['String']>;
  blockHash_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_isNull?: InputMaybe<Scalars['Boolean']>;
  blockHash_lt?: InputMaybe<Scalars['String']>;
  blockHash_lte?: InputMaybe<Scalars['String']>;
  blockHash_not_contains?: InputMaybe<Scalars['String']>;
  blockHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  blockHash_not_endsWith?: InputMaybe<Scalars['String']>;
  blockHash_not_eq?: InputMaybe<Scalars['String']>;
  blockHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  blockHash_not_startsWith?: InputMaybe<Scalars['String']>;
  blockHash_startsWith?: InputMaybe<Scalars['String']>;
  blockNumber_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_isNull?: InputMaybe<Scalars['Boolean']>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not_eq?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicHash_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_gt?: InputMaybe<Scalars['String']>;
  extrinsicHash_gte?: InputMaybe<Scalars['String']>;
  extrinsicHash_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicHash_lt?: InputMaybe<Scalars['String']>;
  extrinsicHash_lte?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicHash_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicHash_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_gt?: InputMaybe<Scalars['String']>;
  extrinsicId_gte?: InputMaybe<Scalars['String']>;
  extrinsicId_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicId_lt?: InputMaybe<Scalars['String']>;
  extrinsicId_lte?: InputMaybe<Scalars['String']>;
  extrinsicId_not_contains?: InputMaybe<Scalars['String']>;
  extrinsicId_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  extrinsicId_not_endsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_not_eq?: InputMaybe<Scalars['String']>;
  extrinsicId_not_in?: InputMaybe<Array<Scalars['String']>>;
  extrinsicId_not_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicId_startsWith?: InputMaybe<Scalars['String']>;
  extrinsicIndex_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_gte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  extrinsicIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  extrinsicIndex_lt?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_lte?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_eq?: InputMaybe<Scalars['Int']>;
  extrinsicIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_endsWith?: InputMaybe<Scalars['String']>;
  id_eq?: InputMaybe<Scalars['String']>;
  id_gt?: InputMaybe<Scalars['String']>;
  id_gte?: InputMaybe<Scalars['String']>;
  id_in?: InputMaybe<Array<Scalars['String']>>;
  id_isNull?: InputMaybe<Scalars['Boolean']>;
  id_lt?: InputMaybe<Scalars['String']>;
  id_lte?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  id_not_endsWith?: InputMaybe<Scalars['String']>;
  id_not_eq?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<Scalars['String']>>;
  id_not_startsWith?: InputMaybe<Scalars['String']>;
  id_startsWith?: InputMaybe<Scalars['String']>;
  no_eq?: InputMaybe<Scalars['Int']>;
  no_gt?: InputMaybe<Scalars['Int']>;
  no_gte?: InputMaybe<Scalars['Int']>;
  no_in?: InputMaybe<Array<Scalars['Int']>>;
  no_isNull?: InputMaybe<Scalars['Boolean']>;
  no_lt?: InputMaybe<Scalars['Int']>;
  no_lte?: InputMaybe<Scalars['Int']>;
  no_not_eq?: InputMaybe<Scalars['Int']>;
  no_not_in?: InputMaybe<Array<Scalars['Int']>>;
  proposalHash_contains?: InputMaybe<Scalars['String']>;
  proposalHash_containsInsensitive?: InputMaybe<Scalars['String']>;
  proposalHash_endsWith?: InputMaybe<Scalars['String']>;
  proposalHash_eq?: InputMaybe<Scalars['String']>;
  proposalHash_gt?: InputMaybe<Scalars['String']>;
  proposalHash_gte?: InputMaybe<Scalars['String']>;
  proposalHash_in?: InputMaybe<Array<Scalars['String']>>;
  proposalHash_isNull?: InputMaybe<Scalars['Boolean']>;
  proposalHash_lt?: InputMaybe<Scalars['String']>;
  proposalHash_lte?: InputMaybe<Scalars['String']>;
  proposalHash_not_contains?: InputMaybe<Scalars['String']>;
  proposalHash_not_containsInsensitive?: InputMaybe<Scalars['String']>;
  proposalHash_not_endsWith?: InputMaybe<Scalars['String']>;
  proposalHash_not_eq?: InputMaybe<Scalars['String']>;
  proposalHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  proposalHash_not_startsWith?: InputMaybe<Scalars['String']>;
  proposalHash_startsWith?: InputMaybe<Scalars['String']>;
  timestamp_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_gt?: InputMaybe<Scalars['DateTime']>;
  timestamp_gte?: InputMaybe<Scalars['DateTime']>;
  timestamp_in?: InputMaybe<Array<Scalars['DateTime']>>;
  timestamp_isNull?: InputMaybe<Scalars['Boolean']>;
  timestamp_lt?: InputMaybe<Scalars['DateTime']>;
  timestamp_lte?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_eq?: InputMaybe<Scalars['DateTime']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['DateTime']>>;
  txIndex_eq?: InputMaybe<Scalars['Int']>;
  txIndex_gt?: InputMaybe<Scalars['Int']>;
  txIndex_gte?: InputMaybe<Scalars['Int']>;
  txIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  txIndex_isNull?: InputMaybe<Scalars['Boolean']>;
  txIndex_lt?: InputMaybe<Scalars['Int']>;
  txIndex_lte?: InputMaybe<Scalars['Int']>;
  txIndex_not_eq?: InputMaybe<Scalars['Int']>;
  txIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  voted_eq?: InputMaybe<Scalars['Boolean']>;
  voted_isNull?: InputMaybe<Scalars['Boolean']>;
  voted_not_eq?: InputMaybe<Scalars['Boolean']>;
  yes_eq?: InputMaybe<Scalars['Int']>;
  yes_gt?: InputMaybe<Scalars['Int']>;
  yes_gte?: InputMaybe<Scalars['Int']>;
  yes_in?: InputMaybe<Array<Scalars['Int']>>;
  yes_isNull?: InputMaybe<Scalars['Boolean']>;
  yes_lt?: InputMaybe<Scalars['Int']>;
  yes_lte?: InputMaybe<Scalars['Int']>;
  yes_not_eq?: InputMaybe<Scalars['Int']>;
  yes_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export type VotesConnection = {
  __typename?: 'VotesConnection';
  edges: Array<VoteEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type WhereIdInput = {
  id: Scalars['String'];
};

export type AllProposalsQueryVariables = Exact<{
  proposalHash?: InputMaybe<Scalars['String']>;
  councilType?: InputMaybe<ProposalCouncilType>;
  status?: InputMaybe<ProposalStatus>;
  result?: InputMaybe<ProposalResult>;
  page?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
}>;

export type AllProposalsQuery = {
  __typename?: 'Query';
  allProposals: {
    __typename?: 'ProposalConnectionModel';
    totalCount: number;
    page: number;
    pageSize: number;
    items: Array<{
      __typename?: 'ProposalModel';
      id: string;
      timestamp: any;
      extrinsicHash?: string | null;
      extrinsicId?: string | null;
      extrinsicIndex?: number | null;
      txIndex?: number | null;
      blockHash?: string | null;
      blockNumber: number;
      account: string;
      proposalIndex?: number | null;
      proposalHash: string;
      threshold?: number | null;
      status: ProposalStatus;
      type: ProposalType;
      module: string;
      method: string;
      parameters?: string | null;
      history?: Array<{
        __typename?: 'ProposalHistoryModel';
        id: string;
        timestamp: any;
        extrinsicHash?: string | null;
        extrinsicId?: string | null;
        extrinsicIndex?: number | null;
        txIndex?: number | null;
        blockHash?: string | null;
        blockNumber: number;
        account?: string | null;
        proposalIndex?: number | null;
        proposalHash: string;
        threshold?: number | null;
        status: ProposalStatus;
        type: ProposalType;
        executedResult?: ProposalExecutionResult | null;
        yes?: number | null;
        no?: number | null;
        module?: string | null;
        method?: string | null;
        parameters?: string | null;
      }> | null;
    }>;
  };
};

export type AssetTransferHistoryQueryVariables = Exact<{
  address: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}>;

export type AssetTransferHistoryQuery = {
  __typename?: 'Query';
  assetTransferHistory: {
    __typename?: 'AssetTransferHistoryConnectionModel';
    page: number;
    pageSize: number;
    totalCount: number;
    items: Array<{
      __typename?: 'AssetTransferHistoryModel';
      amount?: any | null;
      from?: string | null;
      to?: string | null;
      extrinsicHash?: string | null;
      timestamp: any;
      assetId?: string | null;
      type?: string | null;
    }>;
  };
};

export type AssetsQueryVariables = Exact<{ [key: string]: never }>;

export type AssetsQuery = {
  __typename?: 'Query';
  assets: Array<{
    __typename?: 'Asset';
    id: string;
    assetId: any;
    name: string;
    symbol: string;
    decimals: number;
    isFrozen: boolean;
  }>;
};

export type CataDepositsQueryVariables = Exact<{
  limit: Scalars['Int'];
  address: Scalars['String'];
}>;

export type CataDepositsQuery = {
  __typename?: 'Query';
  plugCataDeposits: Array<{
    __typename?: 'PlugCataDeposit';
    id: string;
    blockNumber: number;
    timestamp: any;
    extrinsicHash?: string | null;
    extrinsicId?: string | null;
    extrinsicIndex?: number | null;
    txIndex?: number | null;
    address: string;
    amount: any;
    success: boolean;
  }>;
};

export type CataVoucherRedeemHistoryQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  address?: InputMaybe<Scalars['String']>;
}>;

export type CataVoucherRedeemHistoryQuery = {
  __typename?: 'Query';
  plugCataRedeemHistory: {
    __typename?: 'CatalystVoucherRedeemedTransactionConnectionModel';
    page: number;
    pageSize: number;
    totalCount: number;
    items: Array<{
      __typename?: 'CatalystVoucherRedeemedTransactionModel';
      address?: string | null;
      cataVoucherAmount?: any | null;
      success?: boolean | null;
      timestamp: any;
      extrinsicHash?: string | null;
    }>;
  };
};

export type DexHistoryQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  owner?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DexHistoryType>;
  poolAssetId?: InputMaybe<Scalars['String']>;
}>;

export type DexHistoryQuery = {
  __typename?: 'Query';
  dexHistory: {
    __typename?: 'DexHistoryConnectionModel';
    page: number;
    pageSize: number;
    totalCount: number;
    items: Array<{
      __typename?: 'DexHistoryModel';
      id: string;
      blockNumber: number;
      account?: string | null;
      asset0?: any | null;
      asset1?: any | null;
      asset0Amount?: any | null;
      asset1Amount?: any | null;
      asset?: any | null;
      type?: string | null;
      share?: any | null;
      extrinsicHash?: string | null;
      timestamp: any;
    }>;
  };
};

export type QueryPlugCataDepositHistoryQueryVariables = Exact<{
  address: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;

export type QueryPlugCataDepositHistoryQuery = {
  __typename?: 'Query';
  plugCataDepositHistory: {
    __typename?: 'PlugCatalystDepositConnectionModel';
    page: number;
    pageSize: number;
    totalCount: number;
    items: Array<{
      __typename?: 'PlugCatalystDepositModel';
      address?: string | null;
      amount?: any | null;
      success?: string | null;
      blockHash?: string | null;
      blockNumber: number;
      extrinsicHash?: string | null;
      extrinsicIndex?: number | null;
      extrinsicId?: string | null;
      id: string;
      timestamp: any;
      txIndex?: number | null;
    }>;
  };
};

export type CatalystVoucherPaidTransactionsQueryVariables = Exact<{
  address: Scalars['String'];
}>;

export type CatalystVoucherPaidTransactionsQuery = {
  __typename?: 'Query';
  plugCataPaidOutHistory: {
    __typename?: 'CatalystVoucherAirdropModel';
    address?: string | null;
    shareAmount?: any | null;
    plugAmount?: any | null;
    success?: boolean | null;
    blockHash?: string | null;
    timestamp: any;
    extrinsicHash?: string | null;
  };
};

export type HistoriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  owner?: InputMaybe<Scalars['String']>;
  methodsIn?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type HistoriesQuery = {
  __typename?: 'Query';
  catalystTransferHistory: {
    __typename?: 'CatalystTransactionConnectionModel';
    page: number;
    pageSize: number;
    totalCount: number;
    items: Array<{
      __typename?: 'CatalystTransactionModel';
      assetId?: any | null;
      amount?: any | null;
      blockHash?: string | null;
      blockNumber: number;
      epochId?: any | null;
      extrinsicIndex?: number | null;
      extrinsicId?: string | null;
      extrinsicHash?: string | null;
      module?: string | null;
      owner?: string | null;
      signer?: string | null;
      id: string;
      method?: string | null;
      stakingPoolIndex?: any | null;
      timestamp: any;
      txIndex?: number | null;
    }>;
  };
};

export const AllProposalsDocument = gql`
  query allProposals(
    $proposalHash: String
    $councilType: ProposalCouncilType
    $status: ProposalStatus
    $result: ProposalResult
    $page: Float
    $pageSize: Float
  ) {
    allProposals(
      proposalHash: $proposalHash
      councilType: $councilType
      status: $status
      result: $result
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      page
      pageSize
      items {
        id
        timestamp
        extrinsicHash
        extrinsicId
        extrinsicIndex
        txIndex
        blockHash
        blockNumber
        account
        proposalIndex
        proposalHash
        threshold
        status
        type
        module
        method
        parameters
        history {
          id
          timestamp
          extrinsicHash
          extrinsicId
          extrinsicIndex
          txIndex
          blockHash
          blockNumber
          account
          proposalIndex
          proposalHash
          threshold
          status
          type
          executedResult
          yes
          no
          module
          method
          parameters
        }
      }
    }
  }
`;

/**
 * __useAllProposalsQuery__
 *
 * To run a query within a React component, call `useAllProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProposalsQuery({
 *   variables: {
 *      proposalHash: // value for 'proposalHash'
 *      councilType: // value for 'councilType'
 *      status: // value for 'status'
 *      result: // value for 'result'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useAllProposalsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllProposalsQuery, AllProposalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllProposalsQuery, AllProposalsQueryVariables>(
    AllProposalsDocument,
    options
  );
}
export function useAllProposalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllProposalsQuery, AllProposalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllProposalsQuery, AllProposalsQueryVariables>(
    AllProposalsDocument,
    options
  );
}
export type AllProposalsQueryHookResult = ReturnType<typeof useAllProposalsQuery>;
export type AllProposalsLazyQueryHookResult = ReturnType<typeof useAllProposalsLazyQuery>;
export type AllProposalsQueryResult = Apollo.QueryResult<
  AllProposalsQuery,
  AllProposalsQueryVariables
>;
export const AssetTransferHistoryDocument = gql`
  query assetTransferHistory($address: String!, $page: Int, $pageSize: Int, $type: String) {
    assetTransferHistory(address: $address, page: $page, pageSize: $pageSize, type: $type) {
      items {
        amount
        from
        to
        extrinsicHash
        timestamp
        assetId
        type
      }
      page
      pageSize
      totalCount
    }
  }
`;

/**
 * __useAssetTransferHistoryQuery__
 *
 * To run a query within a React component, call `useAssetTransferHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetTransferHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetTransferHistoryQuery({
 *   variables: {
 *      address: // value for 'address'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAssetTransferHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssetTransferHistoryQuery,
    AssetTransferHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssetTransferHistoryQuery, AssetTransferHistoryQueryVariables>(
    AssetTransferHistoryDocument,
    options
  );
}
export function useAssetTransferHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssetTransferHistoryQuery,
    AssetTransferHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssetTransferHistoryQuery, AssetTransferHistoryQueryVariables>(
    AssetTransferHistoryDocument,
    options
  );
}
export type AssetTransferHistoryQueryHookResult = ReturnType<typeof useAssetTransferHistoryQuery>;
export type AssetTransferHistoryLazyQueryHookResult = ReturnType<
  typeof useAssetTransferHistoryLazyQuery
>;
export type AssetTransferHistoryQueryResult = Apollo.QueryResult<
  AssetTransferHistoryQuery,
  AssetTransferHistoryQueryVariables
>;
export const AssetsDocument = gql`
  query assets {
    assets(limit: 100) {
      id
      assetId
      name
      symbol
      decimals
      isFrozen
    }
  }
`;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<AssetsQuery, AssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
}
export function useAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssetsQuery, AssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
}
export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsQueryResult = Apollo.QueryResult<AssetsQuery, AssetsQueryVariables>;
export const CataDepositsDocument = gql`
  query cataDeposits($limit: Int!, $address: String!) {
    plugCataDeposits(
      limit: $limit
      where: { address_eq: $address, success_eq: true }
      orderBy: timestamp_DESC
    ) {
      id
      blockNumber
      timestamp
      extrinsicHash
      extrinsicId
      extrinsicIndex
      txIndex
      address
      amount
      success
    }
  }
`;

/**
 * __useCataDepositsQuery__
 *
 * To run a query within a React component, call `useCataDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCataDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCataDepositsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCataDepositsQuery(
  baseOptions: Apollo.QueryHookOptions<CataDepositsQuery, CataDepositsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CataDepositsQuery, CataDepositsQueryVariables>(
    CataDepositsDocument,
    options
  );
}
export function useCataDepositsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CataDepositsQuery, CataDepositsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CataDepositsQuery, CataDepositsQueryVariables>(
    CataDepositsDocument,
    options
  );
}
export type CataDepositsQueryHookResult = ReturnType<typeof useCataDepositsQuery>;
export type CataDepositsLazyQueryHookResult = ReturnType<typeof useCataDepositsLazyQuery>;
export type CataDepositsQueryResult = Apollo.QueryResult<
  CataDepositsQuery,
  CataDepositsQueryVariables
>;
export const CataVoucherRedeemHistoryDocument = gql`
  query cataVoucherRedeemHistory($page: Int, $pageSize: Int, $address: String) {
    plugCataRedeemHistory(page: $page, pageSize: $pageSize, address: $address) {
      page
      pageSize
      items {
        address
        cataVoucherAmount
        success
        timestamp
        extrinsicHash
      }
      totalCount
    }
  }
`;

/**
 * __useCataVoucherRedeemHistoryQuery__
 *
 * To run a query within a React component, call `useCataVoucherRedeemHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCataVoucherRedeemHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCataVoucherRedeemHistoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCataVoucherRedeemHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CataVoucherRedeemHistoryQuery,
    CataVoucherRedeemHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CataVoucherRedeemHistoryQuery, CataVoucherRedeemHistoryQueryVariables>(
    CataVoucherRedeemHistoryDocument,
    options
  );
}
export function useCataVoucherRedeemHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CataVoucherRedeemHistoryQuery,
    CataVoucherRedeemHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CataVoucherRedeemHistoryQuery, CataVoucherRedeemHistoryQueryVariables>(
    CataVoucherRedeemHistoryDocument,
    options
  );
}
export type CataVoucherRedeemHistoryQueryHookResult = ReturnType<
  typeof useCataVoucherRedeemHistoryQuery
>;
export type CataVoucherRedeemHistoryLazyQueryHookResult = ReturnType<
  typeof useCataVoucherRedeemHistoryLazyQuery
>;
export type CataVoucherRedeemHistoryQueryResult = Apollo.QueryResult<
  CataVoucherRedeemHistoryQuery,
  CataVoucherRedeemHistoryQueryVariables
>;
export const DexHistoryDocument = gql`
  query dexHistory(
    $page: Float
    $pageSize: Float
    $owner: String
    $type: DexHistoryType
    $poolAssetId: String
  ) {
    dexHistory(
      page: $page
      pageSize: $pageSize
      owner: $owner
      type: $type
      poolAssetId: $poolAssetId
    ) {
      page
      pageSize
      totalCount
      items {
        id
        blockNumber
        account
        asset0
        asset1
        asset0Amount
        asset1Amount
        asset
        type
        share
        extrinsicHash
        timestamp
      }
    }
  }
`;

/**
 * __useDexHistoryQuery__
 *
 * To run a query within a React component, call `useDexHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDexHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDexHistoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      owner: // value for 'owner'
 *      type: // value for 'type'
 *      poolAssetId: // value for 'poolAssetId'
 *   },
 * });
 */
export function useDexHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<DexHistoryQuery, DexHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DexHistoryQuery, DexHistoryQueryVariables>(DexHistoryDocument, options);
}
export function useDexHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DexHistoryQuery, DexHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DexHistoryQuery, DexHistoryQueryVariables>(
    DexHistoryDocument,
    options
  );
}
export type DexHistoryQueryHookResult = ReturnType<typeof useDexHistoryQuery>;
export type DexHistoryLazyQueryHookResult = ReturnType<typeof useDexHistoryLazyQuery>;
export type DexHistoryQueryResult = Apollo.QueryResult<DexHistoryQuery, DexHistoryQueryVariables>;
export const QueryPlugCataDepositHistoryDocument = gql`
  query queryPlugCataDepositHistory($address: String!, $page: Int!, $pageSize: Int!) {
    plugCataDepositHistory(address: $address, page: $page, pageSize: $pageSize) {
      page
      pageSize
      totalCount
      items {
        address
        amount
        success
        blockHash
        blockNumber
        extrinsicHash
        extrinsicIndex
        extrinsicId
        id
        timestamp
        txIndex
      }
    }
  }
`;

/**
 * __useQueryPlugCataDepositHistoryQuery__
 *
 * To run a query within a React component, call `useQueryPlugCataDepositHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryPlugCataDepositHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPlugCataDepositHistoryQuery({
 *   variables: {
 *      address: // value for 'address'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useQueryPlugCataDepositHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryPlugCataDepositHistoryQuery,
    QueryPlugCataDepositHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryPlugCataDepositHistoryQuery,
    QueryPlugCataDepositHistoryQueryVariables
  >(QueryPlugCataDepositHistoryDocument, options);
}
export function useQueryPlugCataDepositHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryPlugCataDepositHistoryQuery,
    QueryPlugCataDepositHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryPlugCataDepositHistoryQuery,
    QueryPlugCataDepositHistoryQueryVariables
  >(QueryPlugCataDepositHistoryDocument, options);
}
export type QueryPlugCataDepositHistoryQueryHookResult = ReturnType<
  typeof useQueryPlugCataDepositHistoryQuery
>;
export type QueryPlugCataDepositHistoryLazyQueryHookResult = ReturnType<
  typeof useQueryPlugCataDepositHistoryLazyQuery
>;
export type QueryPlugCataDepositHistoryQueryResult = Apollo.QueryResult<
  QueryPlugCataDepositHistoryQuery,
  QueryPlugCataDepositHistoryQueryVariables
>;
export const CatalystVoucherPaidTransactionsDocument = gql`
  query catalystVoucherPaidTransactions($address: String!) {
    plugCataPaidOutHistory(address: $address) {
      address
      shareAmount
      plugAmount
      success
      blockHash
      timestamp
      extrinsicHash
    }
  }
`;

/**
 * __useCatalystVoucherPaidTransactionsQuery__
 *
 * To run a query within a React component, call `useCatalystVoucherPaidTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalystVoucherPaidTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalystVoucherPaidTransactionsQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCatalystVoucherPaidTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CatalystVoucherPaidTransactionsQuery,
    CatalystVoucherPaidTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CatalystVoucherPaidTransactionsQuery,
    CatalystVoucherPaidTransactionsQueryVariables
  >(CatalystVoucherPaidTransactionsDocument, options);
}
export function useCatalystVoucherPaidTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CatalystVoucherPaidTransactionsQuery,
    CatalystVoucherPaidTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CatalystVoucherPaidTransactionsQuery,
    CatalystVoucherPaidTransactionsQueryVariables
  >(CatalystVoucherPaidTransactionsDocument, options);
}
export type CatalystVoucherPaidTransactionsQueryHookResult = ReturnType<
  typeof useCatalystVoucherPaidTransactionsQuery
>;
export type CatalystVoucherPaidTransactionsLazyQueryHookResult = ReturnType<
  typeof useCatalystVoucherPaidTransactionsLazyQuery
>;
export type CatalystVoucherPaidTransactionsQueryResult = Apollo.QueryResult<
  CatalystVoucherPaidTransactionsQuery,
  CatalystVoucherPaidTransactionsQueryVariables
>;
export const HistoriesDocument = gql`
  query histories($page: Float, $pageSize: Float, $owner: String, $methodsIn: [String!]) {
    catalystTransferHistory(
      page: $page
      pageSize: $pageSize
      owner: $owner
      methodsIn: $methodsIn
    ) {
      page
      pageSize
      totalCount
      items {
        assetId
        amount
        blockHash
        blockNumber
        epochId
        extrinsicIndex
        extrinsicId
        extrinsicHash
        module
        owner
        signer
        id
        method
        stakingPoolIndex
        timestamp
        txIndex
      }
    }
  }
`;

/**
 * __useHistoriesQuery__
 *
 * To run a query within a React component, call `useHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      owner: // value for 'owner'
 *      methodsIn: // value for 'methodsIn'
 *   },
 * });
 */
export function useHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<HistoriesQuery, HistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HistoriesQuery, HistoriesQueryVariables>(HistoriesDocument, options);
}
export function useHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HistoriesQuery, HistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HistoriesQuery, HistoriesQueryVariables>(HistoriesDocument, options);
}
export type HistoriesQueryHookResult = ReturnType<typeof useHistoriesQuery>;
export type HistoriesLazyQueryHookResult = ReturnType<typeof useHistoriesLazyQuery>;
export type HistoriesQueryResult = Apollo.QueryResult<HistoriesQuery, HistoriesQueryVariables>;
